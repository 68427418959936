import { FormSchema } from 'hooks/useDynamicForm'

export const FIELD_NAMES = {
  username: 'username',
  password: 'password',
  repeatPassword: 'repeatPassword',
}

export const FORM_SCHEMA: FormSchema = [
  {
    fields: [
      {
        _uid: FIELD_NAMES.username,
        type: 'string',
        required: true,
        placeholder: 'Username',
        initialValue: '',
        inputType: 'text',
      },
      {
        _uid: FIELD_NAMES.password,
        type: 'string',
        required: true,
        placeholder: 'Password',
        inputType: 'password',
        validate: (value) => {
          if (!value) {
            return 'Should not be empty'
          }
        },
      },
      {
        _uid: FIELD_NAMES.repeatPassword,
        type: 'string',
        required: true,
        placeholder: 'Repeat password',
        inputType: 'password',
        validate: (value, ctx) => {
          if (!value) {
            return 'Should not be empty'
          }

          if (value !== ctx.values[FIELD_NAMES.password]) {
            return 'Passwords are not matched'
          }
        },
      },
    ],
  },
]
