import cuid from 'cuid'
import arrayMove from 'array-move'
import { checkIsValidProps } from 'utils/checkers'
import { fromArrToMapByKey } from 'utils/map'

import * as I from 'types'

export const getDepSet = (
  aimPool: Map<I.Model.AimId, I.Model.Aim>,
  aimId: I.Model.AimId,
  relationType:
    | 'parent' // return all parents of aimId including
    | 'children', // return all children of aimId including aimId from previous argument
  options: {
    filterBy?: Partial<I.Model.Aim>
    includeArchived?: boolean
  } = {}
): Map<I.Model.AimId, I.Model.Aim> => {
  const { filterBy } = options

  const targetAim = aimPool.get(aimId)

  if (!targetAim) {
    throw Error(`aimId: ${aimId} is not part of aim pool`)
  }

  if (filterBy) {
    if (checkIsValidProps(targetAim, filterBy) === false) {
      return new Map()
    }
  }

  const relatedIds = ((): I.Model.AimId[] => {
    if (relationType === 'parent') return targetAim.parent ? [targetAim.parent] : []

    return options.includeArchived
      ? [...targetAim.children, ...targetAim.archivedChildren]
      : targetAim.children
  })()

  if (relatedIds.length === 0) {
    return new Map<I.Model.AimId, I.Model.Aim>([[targetAim.id, targetAim]])
  }

  const result = new Map([[targetAim.id, targetAim]])

  relatedIds.forEach((id) => {
    const recurrentMap = getDepSet(aimPool, id, relationType, options)
    recurrentMap.forEach((value, key) => result.set(key, value))
  })

  return result
}

export const changeAimOrder = (
  aimById: Map<I.Model.AimId, I.Model.Aim>,
  targetId: I.Model.AimId,
  direction: I.Direction
): Map<I.Model.AimId, I.Model.Aim> /* updated items */ => {
  const target = aimById.get(targetId)!
  const parentId = target.parent

  if (!parentId) return new Map()

  const parent = aimById.get(parentId)!

  switch (direction) {
    case 'up': {
      const grandParentId = parent.parent

      if (!grandParentId) return new Map()

      const grandParent = aimById.get(grandParentId)
      const grandParentChildren = grandParent!.children
      grandParentChildren[grandParentChildren.indexOf(parentId)] = targetId

      const parentChildren = parent.children
      const targetIdx = parentChildren.indexOf(targetId)
      const head = parentChildren.slice(0, targetIdx)
      const tail = parentChildren.slice(Math.min(targetIdx + 1, parentChildren.length), parentChildren.length)
      const updatedParentChildren = [...head, ...target.children, ...tail]

      const updatedTarget: I.Model.Aim = { ...target, parent: grandParentId, children: [parentId] }
      const updatedGrandParent: I.Model.Aim = { ...grandParent!, children: [...grandParentChildren] }
      const updatedParent: I.Model.Aim = { ...parent, parent: targetId, children: updatedParentChildren }

      const updatedItems = new Map<I.Model.AimId, I.Model.Aim>()

      updatedItems.set(updatedTarget.id, updatedTarget)
      updatedItems.set(updatedGrandParent.id, updatedGrandParent)
      updatedItems.set(updatedParent.id, updatedParent)

      target.children.forEach((id) => updatedItems.set(id, { ...aimById.get(id)!, parent: parentId }))

      return updatedItems
    }
    case 'down': {
      const firstChildId = target.children[0]

      if (!firstChildId) return new Map()

      const firstChild = aimById.get(firstChildId)!
      const parentChildren = parent.children

      parentChildren[parentChildren.indexOf(targetId)] = firstChildId

      const updatedItemsList: I.Model.Aim[] = [
        { ...parent, children: [...parentChildren] },
        { ...target, parent: firstChildId, children: firstChild.children },
        { ...firstChild, children: [targetId], parent: parentId },
        ...firstChild.children.map((id): I.Model.Aim => ({ ...aimById.get(id)!, parent: targetId })),
      ]

      return fromArrToMapByKey(updatedItemsList, 'id')
    }
    case 'left':
    case 'right': {
      const aimIdx = parent.children.indexOf(targetId)
      const moveTo = direction === 'left' ? -1 : 1
      const moveToIdx =
        moveTo > 0
          ? Math.min(aimIdx + moveTo, parent.children.length - 1)
          : aimIdx + moveTo > 0
          ? aimIdx + moveTo
          : 0

      return fromArrToMapByKey<I.Model.Aim, 'id'>(
        [{ ...parent, children: arrayMove(parent.children, aimIdx, moveToIdx) }],
        'id'
      )
    }
  }
}

export const changeAimParent = (
  aimById: Map<I.Model.AimId, I.Model.Aim>,
  aimId: I.Model.AimId,
  parentId: I.Model.AimId
): Map<I.Model.AimId, I.Model.Aim> /* updated items */ => {
  const dragged = aimById.get(aimId)!
  const dropped = aimById.get(parentId)!
  const parentAim = dragged.parent && aimById.get(dragged.parent)

  const updatedItems = new Map<I.Model.AimId, I.Model.Aim>()

  if (parentAim) {
    updatedItems.set(parentAim.id, {
      ...parentAim,
      children: parentAim.children.filter((x) => x !== aimId),
    })
  }

  updatedItems.set(aimId, { ...dragged, parent: parentId })

  updatedItems.set(parentId, {
    ...dropped,
    children: [aimId, ...dropped.children.filter((x) => x !== aimId)],
  })

  return updatedItems
}

export const getNextSelectedAimControlPanelState = (
  s: I.AppState.TreeBoard,
  direction: I.Direction,
  usePrevSelectedChildren: boolean,
  foldedAims: Map<I.RootAimId, Set<I.Model.AimId>>
): { selectedAimId: I.Model.AimId; prevSelectedChildren?: I.Model.AimId } | null => {
  const selectedAimId = s.controlPanel.selectedAimId
  const rootAimId = s.controlPanel.rootAimId

  if (!selectedAimId || !rootAimId) {
    return null
  }

  const selectedAim = s.aims.get(selectedAimId)

  if (!selectedAimId) {
    throw Error('selectedAim is missing')
  }

  if (selectedAimId == s.controlPanel.rootAimId) {
    const notAllowedDirections: I.Direction[] = ['up', 'left', 'right']

    if (notAllowedDirections.includes(direction)) {
      return null
    }
  }

  if (direction === 'up' || direction === 'down') {
    /// If previously selected aim is folded we should not select further down
    if (direction === 'down' && foldedAims.get(rootAimId)?.has(selectedAimId)) return null

    const newSelectedAimId = direction === 'up' ? selectedAim!.parent : selectedAim!.children[0]

    if (!newSelectedAimId) {
      return null
    }

    const prevSelectedChildren = usePrevSelectedChildren ? s.controlPanel.prevSelectedChildren : null

    const _aimId =
      prevSelectedChildren && direction === 'down' && selectedAim!.children.includes(prevSelectedChildren)
        ? prevSelectedChildren
        : newSelectedAimId

    return {
      prevSelectedChildren: selectedAimId,
      selectedAimId: _aimId,
    }
  }

  if (direction === 'right' || direction === 'left') {
    const parentAim = selectedAim!.parent && s.aims.get(selectedAim!.parent)

    if (!parentAim) {
      return null
    }

    const children = parentAim.children
    const curIdx = children.indexOf(selectedAimId)
    const newIdx = direction === 'right' ? curIdx + 1 : curIdx - 1

    if (newIdx < 0 || newIdx > children.length - 1) {
      return null
    }

    return { selectedAimId: children[newIdx] }
  }

  return null
}

export function findNodeBy(node: I.TreeNode, by: (node: I.TreeNode) => boolean): I.TreeNode | undefined {
  if (by(node)) {
    return node
  }

  const children = node.children

  if (children) {
    for (let i = 0; i < children.length; i++) {
      const resultNode = findNodeBy(children[i], by)

      if (resultNode) {
        return resultNode
      }
    }
  }
}

export const generateNewAim = (
  data: Partial<I.Model.Aim> & {
    name: string
  },
  userId: I.Model.UserId
): I.Model.Aim => {
  const newAimId = cuid() as I.Model.AimId

  return {
    id: newAimId,
    children: [],
    archivedChildren: [],
    canRead: [userId],
    canWrite: [userId],
    ...data,
  }
}

export function getCorrectDirection(
  direction: I.Direction,
  p: {
    aims: Map<I.Model.AimId, I.Model.Aim>
    foldedAims: Map<I.RootAimId, Set<I.Model.AimId>>
    selectedAimId: I.Model.AimId | null
    rootAimId: I.RootAimId | null
    showAimsWithoutChildrenVerticalList: boolean
  }
): I.Direction | null {
  const { selectedAimId, rootAimId } = p

  if (!selectedAimId || !rootAimId) return null

  if (p.showAimsWithoutChildrenVerticalList !== true) return null
  if (selectedAimId === rootAimId) return null

  const aim = p.aims.get(selectedAimId)!
  const parentId = aim.parent

  if (!parentId) return null
  if (parentId === p.rootAimId) return null

  const parentAim = p.aims.get(parentId)!

  const childrenIds = parentAim.children

  const childrenHasChildren =
    childrenIds.find(
      (id) => p.aims.get(id)!.children.length > 0 && p.foldedAims.get(rootAimId)?.has(id) !== true
    ) !== undefined

  if (!childrenHasChildren) {
    switch (direction) {
      case 'up':
        return childrenIds[0] === selectedAimId ? 'up' : 'left'
      case 'down':
        return 'right'
      case 'left':
        return 'up'
      case 'right':
        return 'down'
    }
  }

  return null
}
