import React, { useEffect, useLayoutEffect, useState, useRef, useCallback, useMemo } from 'react'
import { useKeyboard, KeyboardEventSubscriber, Key, WithKey } from 'hooks/useKeyboard'

export type Values = {
  name: string
}

export type Props = {
  initialValues: Partial<Values>
  onSubmit: (values: Values) => void
  onCancel?: () => void
}

function setCursorPositionOnEditable(el: HTMLDivElement) {
  const selection = window.getSelection()
  const range = document.createRange()

  if (selection) {
    selection.removeAllRanges()
    range.selectNodeContents(el)
    range.collapse(false)
    selection.addRange(range)
    el.focus()
  }
}

function AimFormRender(p: Props) {
  const { onSubmit, onCancel } = p
  const editableElRef = useRef<HTMLDivElement>(null)
  const nameInitialValue = p.initialValues.name || ''

  const handleSubmit = useCallback(() => {
    const editableEl = editableElRef.current

    if (editableEl) {
      const updatedName = editableEl.innerText.trim()
      onSubmit({ name: updatedName })
    }
  }, [onSubmit])

  const keymap = useMemo(
    (): KeyboardEventSubscriber[] => [
      { key: [Key.escape], type: 'keyup', on: () => onCancel && onCancel() },
      { key: [Key.enter], type: 'keydown', on: handleSubmit },
      { key: [Key.enter], with: [WithKey.shift], type: 'keydown', on: (e) => e.preventDefault() },
    ],
    []
  )

  useKeyboard(keymap)

  useEffect(() => {
    if (editableElRef.current) {
      editableElRef.current.textContent = nameInitialValue

      setCursorPositionOnEditable(editableElRef.current)
    }
  }, [])

  return <div ref={editableElRef} contentEditable onBlur={handleSubmit} />
}

export default function AimForm(p: Props) {
  const [shouldRender, setShouldRender] = useState(false)

  /* If Aim created by ENTER key we have side
   * effect when new Aim appears with break line
   * this hack deals with this issue */

  useLayoutEffect(() => {
    setTimeout(() => {
      setShouldRender(true)
    }, 0)
  }, [])

  if (!shouldRender) return <>{p.initialValues.name || ''}</>

  return <AimFormRender {...p} />
}
