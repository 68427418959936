/* based on https://github.com/hackape/Detect-Vimium/blob/master/detect-vimium.js */

export default function hasVimium(): boolean {
  const spy = document.createElement('div')

  spy.className = 'vimiumReset'
  spy.style.display = 'none'
  document.body.appendChild(spy)

  const isInstalled = window.getComputedStyle(spy).zIndex === '2140000000'
  document.body.removeChild(spy)

  return isInstalled
}
