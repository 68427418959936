import { FormSchema } from 'hooks/useDynamicForm'

export const FIELD_NAMES = {
  username: 'username',
  password: 'password',
}

const RESET_ERRORS_STATE = {
  [FIELD_NAMES.username]: undefined,
  [FIELD_NAMES.password]: undefined,
}

export const FORM_SCHEMA: FormSchema = [
  {
    fields: [
      {
        _uid: FIELD_NAMES.username,
        type: 'string',
        required: true,
        placeholder: 'Username',
        initialValue: '',
        onChange: (_, ctx) => ctx.controls.errors.merge(RESET_ERRORS_STATE),
      },
      {
        _uid: FIELD_NAMES.password,
        type: 'string',
        required: true,
        placeholder: 'Password',
        initialValue: '',
        inputType: 'password',
        onChange: (_, ctx) => ctx.controls.errors.merge(RESET_ERRORS_STATE),
      },
    ],
  },
]
