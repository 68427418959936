import React from 'react'
import { Route, Switch } from 'react-router-dom'
import showcases from './showcased-components'

const getShowcaseRoutePath = (subRoutePath: string) => `/showcase/${subRoutePath}`

export default function Showcase() {
  return (
    <Switch>
      {showcases.map(([cmp, subRoutePath]) => (
        <Route key={subRoutePath} path={getShowcaseRoutePath(subRoutePath)} component={cmp} />
      ))}

      <div>
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        {showcases.map(([_, subRoutePath, [title, description]]) => (
          <a key={subRoutePath} href={getShowcaseRoutePath(subRoutePath)}>
            {title} – {description}
          </a>
        ))}
      </div>
    </Switch>
  )
}
