import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 24px;
  background-color: #fff;
  height: 100%;
  color: #000;
`

export const Title = styled.div`
  font-size: 24px;
`

/* SettingsBlock */

export const SettingsBlock = styled.div`
  margin-top: 14px;
`

export const SettingsBlockHead = styled.div`
  font-size: 18px;
`

export const SettingsBlockBody = styled.div``

/* SettingProfiles */

export const SettingProfiles = styled.div``
export const SettingProfilesControls = styled.div`
  margin: 12px 0px;
`

/* Specific settings section */

export const Setting = styled.div`
  margin-top: 22px;
`

/* SwitchSetting */

export const SwitchSetting = styled(Setting)`
  display: flex;
  align-items: center;
`

export const SwitchSettingName = styled.div``

/* ColorSetting */

export const ColorSetting = styled(Setting)``
export const ColorSettingName = styled.div`
  margin-bottom: 8px;
`

/* SelectSetting */

export const SelectSetting = styled(Setting)``
export const SelectSettingName = styled.div`
  margin-bottom: 8px;
`
