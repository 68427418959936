import { useRef, useEffect, useMemo } from 'react'
import { useStore } from 'effector-react'
import { useDrop } from 'react-dnd'

import { animate } from 'utils/animations'
import * as DOM from 'utils/DOM'
import * as feature from 'features'

import useSelector from 'hooks/useSelector'

import * as I from 'types'

export function useIsFolded(id: I.Model.AimId, rootAimId: I.RootAimId) {
  const foldedAims = useStore(feature.treeBoard.$foldedAims)
  return foldedAims.get(rootAimId)?.has(id) || false
}

export function useAimItem(id: I.Model.AimId, rootAimId: I.RootAimId) {
  const isRoot = id === rootAimId
  const isFolded = useIsFolded(id, rootAimId)

  const scrollIntoViewElRef = useRef<null | HTMLDivElement>(null)

  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: 'aim',
    drop: (item: I.DnDItem) =>
      feature.treeBoard.event.aim.move({
        aimOnly: false,
        options: { type: 'parent', aimId: item.id, parentId: id },
      }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const name = useSelector(
    feature.treeBoard.$aimById,
    (aimById): string | undefined => aimById.get(id)?.name,
    id
  )

  const hasChildren = useSelector(
    feature.treeBoard.$aimById,
    (aimById) => !!aimById.get(id)?.children.length,
    id
  )

  const orderIndex = useSelector(
    feature.treeBoard.$aimById,
    (aimById) => {
      const aim = aimById.get(id)!

      if (aim.parent) {
        const parent = aimById.get(aim.parent)!

        return parent.children.indexOf(id)
      }
    },
    id
  )

  const isTracking = useSelector(feature.timeTracker.$timeTrackerStore, (s) => id === s.trackingAimId, id)

  const controlPanel = useStore(feature.treeBoard.$controlPanel)
  const isSelected = controlPanel.selectedAimId === id
  const isFirstRootChild = feature.treeBoard.utils.isFirstRootChild(id)
  const isActive = canDrop && isOver

  const modifyPhase = controlPanel.modifyPhase

  useEffect(() => {
    if (isSelected) {
      scrollIntoAimItemView(id)
    }
  }, [isSelected, id, orderIndex])

  const handlers = useMemo(() => {
    const modify = feature.treeBoard.event.aim.modify

    return {
      onOpenAimDetails: () => feature.crossStoreEvents.openAimDetails(),
      onSelectAim: () => feature.treeBoard.event.aim.moveSelectionPointerToItemId(id),
      onEditingComplete: (newName: string) => modify.editing.complete({ updatedName: newName }),
      onRemovingConfirmed: () => modify.removing.complete(),
      onRemovingDenied: () => modify.removing.cancel(),
      onCreatingComplete: (name: string) => modify.creating.complete({ name }),
      onCreatingCancelled: () => modify.creating.cancel(),
    }
  }, [id])

  return {
    ...handlers,
    name,
    isActive,
    isSelected,
    isRoot,
    isTracking,
    isFirstRootChild,
    isFolded,
    hasChildren,
    scrollIntoViewElRef,
    dropRef,
    modifyPhase,
  }
}

export function scrollIntoAimItemView(aimId: I.Model.AimId) {
  const aimItemEl = DOM.getEl.aimItem(aimId)
  const branchEl = DOM.getEl.aimBranch(aimId)
  const scrollEl = DOM.getEl.treeBoardScrollEl()

  if (!branchEl || !scrollEl || !aimItemEl) return

  const branchRect = branchEl.getBoundingClientRect()
  const scrollElRect = scrollEl.getBoundingClientRect()
  const aimRect = aimItemEl.getBoundingClientRect()

  const isRootBranch = branchEl.dataset.root === 'true'

  const scrollLeftDiff = ((): number | null => {
    const requiredExtraSpace = 8
    const branchAreaWidth = branchRect.width + requiredExtraSpace

    const isLeftEdgeFitsVisibleArea = branchRect.x - scrollElRect.x >= 0
    const isRightEdgeFitsVisibleArea = branchRect.x + branchAreaWidth <= scrollElRect.width + scrollElRect.x

    const isCorrectionIsNotRequired = isRightEdgeFitsVisibleArea && isLeftEdgeFitsVisibleArea

    if (isCorrectionIsNotRequired) return null

    const isTargetElementFitVisibleArea = branchAreaWidth <= scrollElRect.width

    if (isTargetElementFitVisibleArea) {
      const widthDiff = scrollElRect.width - branchAreaWidth
      const spaceDiff = widthDiff + scrollElRect.x - branchRect.x

      if (spaceDiff < 0) {
        return Math.abs(spaceDiff)
      }
    }

    return branchRect.x - scrollElRect.x
  })()

  const scrollTopDiff = ((): number | null => {
    if (isRootBranch) return scrollEl.scrollTop * -1

    const topEdgeDiff = aimRect.y - scrollElRect.y

    if (topEdgeDiff < 0) return topEdgeDiff

    const extraSpaceForBottom = 32
    const bottomEdgeDiff =
      aimRect.y + aimRect.height + extraSpaceForBottom - (scrollElRect.y + scrollElRect.height)

    if (bottomEdgeDiff > 0) return bottomEdgeDiff

    return null
  })()

  if (!scrollLeftDiff && !scrollTopDiff) return

  const initialScrollLeft = scrollEl.scrollLeft
  const initialScrollTop = scrollEl.scrollTop

  animate({
    duration: 150,
    draw: (progress) => {
      if (scrollLeftDiff) {
        scrollEl.scrollLeft = initialScrollLeft + scrollLeftDiff * progress
      }

      if (scrollTopDiff) {
        scrollEl.scrollTop = initialScrollTop + scrollTopDiff * progress
      }
    },
  })
}
