import React, { useMemo } from 'react'
import { Button, Input } from '@material-ui/core'

import * as settingsFeature from 'features/settings'

import useDynamicForm, { FormConfig } from 'hooks/useDynamicForm'

import Modal from 'components/Modal'
import Select from 'components/Select'

import * as El from './AddNewProfileModal.styles'

import * as I from 'types'

type Props = {
  selectedProfileId: I.SettingProfileId
  profileOptions: I.SettingProfileId[]
  onClose: () => void
}

const PROFILE_NAME_FIELD_ID = 'PROFILE_NAME'
const BASED_ON_FIELD_ID = 'BASED_ON'

export default function AddNewProfileModal(p: Props) {
  const formConfig = useMemo(
    (): FormConfig => ({
      handleSubmit: (values) =>
        new Promise((_, reject) => {
          const newProfileName = values[PROFILE_NAME_FIELD_ID]

          if (p.profileOptions.includes(newProfileName)) {
            return reject('This profile name is already taken')
          }

          const basedOn = values[BASED_ON_FIELD_ID]

          settingsFeature.addNewProfile({ basedOn, newProfileName })
          p.onClose()
        }),
      schema: [
        {
          fields: [
            { type: 'string', _uid: PROFILE_NAME_FIELD_ID, required: true },
            { type: 'string', _uid: BASED_ON_FIELD_ID, initialValue: p.selectedProfileId },
          ],
        },
      ],
    }),
    []
  )

  const form = useDynamicForm(formConfig)
  const [{ fields }] = form.fieldGroups
  const [profileNameField, basedOnField] = fields

  if (profileNameField.type !== 'string') return null
  if (basedOnField.type !== 'string') return null

  return (
    <Modal onOverlayClick={p.onClose}>
      <El.Wrapper>
        <form onSubmit={form.handleSubmit}>
          <El.SelectLabel>By which profile new one should be based on:</El.SelectLabel>
          <Select options={p.profileOptions} value={basedOnField.value} onChange={basedOnField.onChange} />

          <El.InputLabel>New profile name</El.InputLabel>
          <Input
            fullWidth
            value={profileNameField.value || ''}
            onChange={(e) => profileNameField.onChange(e.target.value)}
          />

          {form.submittingError.isError && (
            <El.ValidationError>{form.submittingError.message}</El.ValidationError>
          )}

          <El.Controls>
            <Button
              variant="contained"
              type="submit"
              disabled={!form.isValid || form.isSubmitting || form.submittingError.isError}
            >
              Add
            </Button>
            <Button variant="contained" onClick={p.onClose}>
              Cancel
            </Button>
          </El.Controls>
        </form>
      </El.Wrapper>
    </Modal>
  )
}
