import { merge, cloneDeep } from 'lodash'
import { omit } from '../utils/record'
import { createStore, createEvent } from 'effector'
import withPersist from 'hocs/withPersist'

import { DefaultTheme } from 'styled-components'
import { DeepPartial } from 'utility-types'

import * as I from 'types'

const SETTINGS_PROFILE_DARK: DefaultTheme = {
  id: 'Dark',
  isFactoryPresent: true,
  general: {
    font: {
      family: 'Roboto',
      color: 'rgba(222, 200, 200, 1)',
    },
  },
  treeBoard: {
    behaviour: {
      usePreviousSelectedChildren: false,
      showAimsWithoutChildrenVerticalList: true,
    },
    head: { height: 100 },
    background: {
      color: 'rgba(23, 16, 16, 1)',
      dendrogram: {
        enabled: true,
      },
    },
    settingsPanel: {
      width: 400,
    },
    aim: {
      width: 240,
      backgroundColor: {
        initial: 'rgba(54, 34, 34, 0.4)',
        selected: 'rgba(54, 34, 34, 0.8)',
      },
    },
  },
}

const SETTINGS_PROFILE_LIGHT: DefaultTheme = merge(cloneDeep(SETTINGS_PROFILE_DARK), {
  id: 'Light',
  treeBoard: { background: { color: 'rgba(255, 255, 255, 1)' } },
})

const FACTORY_PROFILES = {
  [SETTINGS_PROFILE_DARK.id]: SETTINGS_PROFILE_DARK,
  [SETTINGS_PROFILE_LIGHT.id]: SETTINGS_PROFILE_LIGHT,
}

const INITIAL_SETTINGS: I.AppState.Settings = {
  selectedProfile: SETTINGS_PROFILE_DARK.id,
  profiles: FACTORY_PROFILES,
}

export const $settings = withPersist(
  createStore<I.AppState.Settings>(INITIAL_SETTINGS, { name: 'GENERAL_SETTINGS' })
)

export const updateCurrentProfile = createEvent<DeepPartial<DefaultTheme>>()
export const resetSelectedProfileToDefault = createEvent()
export const selectProfile = createEvent<I.SettingProfileId>()
export const resetSettings = createEvent()

export const addNewProfile =
  createEvent<{ basedOn: I.SettingProfileId; newProfileName: I.SettingProfileId }>()
export const deleteProfile = createEvent()

$settings.reset(resetSettings)

$settings.on(updateCurrentProfile, (s, p) => {
  const selectedProfile = s.profiles[s.selectedProfile]
  const updatedSelectedProfile: DefaultTheme = merge(cloneDeep(selectedProfile), p)

  return {
    ...s,
    profiles: {
      ...s.profiles,
      [updatedSelectedProfile.id]: updatedSelectedProfile,
    },
  }
})

$settings.on(resetSelectedProfileToDefault, (s) => {
  const selectedProfile = s.profiles[s.selectedProfile]

  if (selectedProfile.isFactoryPresent === false) return s

  return {
    ...s,
    profiles: {
      ...s.profiles,
      [selectedProfile.id]: FACTORY_PROFILES[selectedProfile.id],
    },
  }
})

$settings.on(selectProfile, (s, profileId) => ({ ...s, selectedProfile: profileId }))

$settings.on(addNewProfile, (s, { basedOn, newProfileName }) => {
  const selectedProfile = s.profiles[basedOn]
  const newProfile = cloneDeep(selectedProfile)

  newProfile.id = newProfileName
  newProfile.isFactoryPresent = false

  return {
    ...s,
    selectedProfile: newProfile.id,
    profiles: { ...s.profiles, [newProfile.id]: newProfile },
  }
})

$settings.on(deleteProfile, (s) => {
  const selectedProfile = s.profiles[s.selectedProfile]

  if (selectedProfile.isFactoryPresent) return s

  return {
    ...s,
    profiles: omit(s.profiles, [selectedProfile.id]),
    selectedProfile: INITIAL_SETTINGS.selectedProfile,
  }
})

export function getCurrentSettingsProfile() {
  const s = $settings.getState()
  return s.profiles[s.selectedProfile]
}
