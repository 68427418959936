import React, { useState } from 'react'
import { Dialog, Button, LinearProgress } from '@material-ui/core'
import './ConfirmDenyDialog.scss'

import { useKeyboard, Key } from 'hooks/useKeyboard'

type Props = {
  children: React.ReactNode
  open: boolean

  onConfirm: () => void
  onDeny: () => void

  confirmBtnText?: string
  denyBtnText?: string
  loading?: boolean
  error?: string
}

const CN = 'ConfirmDenyDialog'

export function useConfirmDenyDialog() {
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<string>()

  const set = {
    loading: setLoading,
    error: setError,
    open: setOpen,
  }

  return { set, loading, error, open }
}

export default function ConfirmDenyDialog(p: Props) {
  const { confirmBtnText = 'Confirm', denyBtnText = 'Deny' } = p

  function withLoading<T>(callback: T): T {
    if (p.loading) {
      const emptyCb: any = () => undefined
      return emptyCb
    }

    return callback
  }

  useKeyboard([
    { key: [Key.enter], type: 'keydown', on: () => withLoading(p.onConfirm)() },
    { key: [Key.escape], type: 'keydown', on: () => withLoading(p.onDeny)() },
  ], !p.open)

  return (
    <Dialog open={p.open} onClose={withLoading(p.onDeny)}>
      <div className={CN}>
        {p.loading && (
          <div className={`${CN}__loading`}>
            <LinearProgress />
          </div>
        )}

        <div className={`${CN}__children`}>{p.children}</div>

        {p.error && <div className={`${CN}__error`}>{p.error}</div>}

        <div className={`${CN}__buttons`}>
          <Button onClick={p.onConfirm} variant="contained" color="primary" disabled={p.loading}>
            {confirmBtnText}
          </Button>
          <Button onClick={p.onDeny} disabled={p.loading}>
            {denyBtnText}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
