import React from 'react'
import Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import { ThemeProvider } from 'styled-components'

import useTheme from 'hooks/useTheme'

export default function RootProviders(p: { children: React.ReactNode }) {
  const theme = useTheme()

  return (
    <DndProvider backend={Backend}>
      <ThemeProvider theme={theme}>{p.children}</ThemeProvider>
    </DndProvider>
  )
}
