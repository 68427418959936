import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const DateTime = styled.div`
  white-space: nowrap;
`

export const Name = styled.div`
  margin-left: 14px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const RemoveBtn = styled.button`
  margin-left: 12px;
`

export const EditBtn = styled.button`
  margin-left: 4px;
`
