import { useEffect } from 'react'
import { useStore } from 'effector-react'

import {
  getAimTreeKeymap,
  getAimDetailsModalKeymap,
  getSettingsPanelKeymap,
} from 'components/AimTree/AimTree.keymap'
import { useKeyboard } from 'hooks/useKeyboard'

import { getDepSet } from 'utils/tree'
import qp from 'utils/routing'

import * as feature from 'features'

import * as I from 'types'

export function useTreeBoard(rootAimId: I.RootAimId) {
  const treeBoardState = feature.treeBoard.$treeBoardStore.getState()
  const timeTrackerState = feature.timeTracker.$timeTrackerStore.getState()

  useEffect(() => {
    const qpDetailsAimId = qp.parse().detailsAimId || null
    const detailsAimId = feature.page.$treeBoardPage.getState().detailsAimId

    if (qpDetailsAimId !== detailsAimId) {
      feature.page.event.setDetailsAimId(qpDetailsAimId)
    }

    if (treeBoardState.controlPanel.rootAimId !== rootAimId) {
      setTimeout(() => {
        /* Probably because of route transition we have weird side effect 
           when some previous event rewrites state of this event therefore we have timeout here */
        feature.treeBoard.event.interface.selectRootAimId(rootAimId)
      }, 0)
    }

    const newSelectedAimId = ((): I.Model.AimId | null => {
      const selectedAimId = treeBoardState.controlPanel.selectedAimId

      if (selectedAimId === rootAimId) return null

      const trackingAimId = timeTrackerState.trackingAimId
      const rootAimDependencies = getDepSet(treeBoardState.aims, rootAimId, 'children')

      if (selectedAimId && rootAimDependencies.get(selectedAimId)) return selectedAimId
      if (trackingAimId && rootAimDependencies.get(trackingAimId)) return trackingAimId

      return rootAimId
    })()

    if (newSelectedAimId) {
      feature.treeBoard.event.aim.moveSelectionPointerToItemId(
        findOldestFoldedParent({
          foldedAims: feature.treeBoard.$foldedAims.getState(),
          id: newSelectedAimId,
          rootId: rootAimId,
          aims: treeBoardState.aims,
        }) || newSelectedAimId
      )
    }
  }, [rootAimId])

  const rootAim = treeBoardState.aims.get(rootAimId)
  return rootAim ? !rootAim.isArchived : false
}

const AIM_TREE_KEYMAP = getAimTreeKeymap()
const AIM_DETAILS_KEYMAP = getAimDetailsModalKeymap()
const SETTINGS_PANEL_KEYMAP = getSettingsPanelKeymap()

export function useTreeBoardKeyboard() {
  const panel = useStore(feature.page.$panels)
  const treeBoardPage = useStore(feature.page.$treeBoardPage)

  useKeyboard(AIM_DETAILS_KEYMAP, !treeBoardPage.detailsAimId)
  useKeyboard(AIM_TREE_KEYMAP, panel.settings || !!treeBoardPage.detailsAimId)
  useKeyboard(SETTINGS_PANEL_KEYMAP, !panel.settings)

  return { panel, detailsAimId: treeBoardPage.detailsAimId }
}

function findOldestFoldedParent(p: {
  id: I.Model.AimId
  rootId: I.RootAimId
  aims: Map<I.Model.AimId, I.Model.Aim>
  foldedAims: Map<I.RootAimId, Set<I.Model.AimId>>
}): I.Model.AimId | null {
  if (p.id === p.rootId) return null

  const isFolded = p.foldedAims.get(p.rootId)?.has(p.id) === true

  const aimParentId = p.aims.get(p.id)!.parent

  if (isFolded) {
    return aimParentId //
      ? findOldestFoldedParent({ ...p, id: aimParentId }) || p.id
      : p.id
  }

  if (!aimParentId) return null

  const isParentFolded = p.foldedAims.get(p.rootId)?.has(p.id) === true

  return isParentFolded //
    ? findOldestFoldedParent({ ...p, id: aimParentId }) || aimParentId
    : findOldestFoldedParent({ ...p, id: aimParentId })
}
