import { useEffect, useMemo } from 'react'

export enum WithKey {
  alt,
  ctrl,
  cmd,
  shift,
}

/* Enum values should mirror `KeyboardEvent.code` string */
export enum Key {
  enter = 'Enter',
  escape = 'Escape',
  backspace = 'Backspace',
  space = 'Space',
  tab = 'Tab',

  arrowLeft = 'ArrowLeft',
  arrowRight = 'ArrowRight',
  arrowUp = 'ArrowUp',
  arrowDown = 'ArrowDown',

  b = 'KeyB',
  c = 'KeyC',
  d = 'KeyD',
  f = 'KeyF',
  h = 'KeyH',
  i = 'KeyI',
  j = 'KeyJ',
  k = 'KeyK',
  l = 'KeyL',
  m = 'KeyM',
  n = 'KeyN',
  o = 'KeyO',
  p = 'KeyP',
  t = 'KeyT',
  v = 'KeyV',
  x = 'KeyX',
}

/*
  same handler allowed to use for multiple shortcuts for example:

  `{ key: [Key.ArrowLeft, Key.h], with: [WithKey.shift, WithKey.ctrl], ... }

  result shortcut scheme for the given `on` handler will be:
    - ArrowLeft + Shift
    - ArrowLeft + Ctrl
    - h + Shift
    - h + Ctrl

  If `with` key is not present and one of `WithKey` is pressed
  the `on` callback will not be called 
*/
export type KeyboardEventSubscriber = {
  key: Key[]
  with?: WithKey[]
  type: 'keyup' | 'keydown'
  description?: string
  on: (e: KeyboardEvent) => void
}

type OptimizedKeyTree = Map<
  Key,
  Map<
    'keyup' | 'keydown',
    { with: Map<WithKey, Array<(e: KeyboardEvent) => void>>; direct: Array<(e: KeyboardEvent) => void> }
  >
>

/// Create tree structure for direct access to callbacks
const mapOptimizedKeyTree = (subs: KeyboardEventSubscriber[]): OptimizedKeyTree => {
  const keyTree: OptimizedKeyTree = new Map()

  subs.forEach((sub) => {
    sub.key.forEach((key) => {
      if (keyTree.has(key) == false) {
        const initialMap: Map<
          'keyup' | 'keydown',
          { with: Map<WithKey, Array<(e: KeyboardEvent) => void>>; direct: Array<(e: KeyboardEvent) => void> }
        > = new Map()

        initialMap.set('keydown', { with: new Map(), direct: [] })
        initialMap.set('keyup', { with: new Map(), direct: [] })

        keyTree.set(key, initialMap)
      }

      const currentKeyMap = keyTree.get(key)?.get(sub.type)

      if (!currentKeyMap) return

      if (sub.with) {
        sub.with.forEach((withKey) => {
          const keyMap = currentKeyMap.with.get(withKey)

          keyMap //
            ? keyMap.push(sub.on)
            : currentKeyMap.with.set(withKey, [sub.on])
        })

        return
      }

      currentKeyMap.direct.push(sub.on)
    })
  })

  return keyTree
}

const makeOnKeyFactory = (subs: KeyboardEventSubscriber[], disabled: boolean) => {
  const keyTree = mapOptimizedKeyTree(subs)

  return (pressType: 'keyup' | 'keydown') => (e: KeyboardEvent) => {
    if (e.type !== pressType || disabled) {
      return
    }

    const key = e.code as Key
    const keySpecificTree = keyTree.get(key)?.get(pressType)

    if (!keySpecificTree) return

    if (!e.shiftKey && !e.altKey && !e.metaKey && !e.ctrlKey) {
      const direct = keySpecificTree.direct

      if (direct) {
        direct.forEach((cb) => cb(e))
      }
    }

    const withKey = keySpecificTree.with

    if (!withKey) return

    withKey.forEach((cbList, k) => {
      switch (k) {
        case WithKey.alt:
          if (e.altKey) cbList.forEach((cb) => cb(e))
          break

        case WithKey.shift:
          if (e.shiftKey) cbList.forEach((cb) => cb(e))
          break

        case WithKey.ctrl:
          if (e.ctrlKey) cbList.forEach((cb) => cb(e))
          break

        case WithKey.cmd:
          if (e.metaKey) cbList.forEach((cb) => cb(e))
          break

        default:
          throw Error('Unsupported WithKey')
      }
    })
  }
}

export function useKeyboard(subs: KeyboardEventSubscriber[], disabled = false) {
  const handle = useMemo(() => {
    if (disabled) return

    const makeOnKey = makeOnKeyFactory(subs, disabled)

    const keyUp = makeOnKey('keyup')
    const keyDown = makeOnKey('keydown')

    return { keyUp, keyDown }
  }, [subs, disabled])

  useEffect(() => {
    if (handle) {
      window.addEventListener('keyup', handle.keyUp)
      window.addEventListener('keydown', handle.keyDown)
    }

    return () => {
      if (handle) {
        window.removeEventListener('keyup', handle.keyUp)
        window.removeEventListener('keydown', handle.keyDown)
      }
    }
  }, [handle])
}
