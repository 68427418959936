import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  min-width: ${(p) => p.theme.treeBoard.aim.width}px;
`

export const Body = styled.div<{ isRoot: boolean; isEditing: boolean; isSelected: boolean }>`
  width: ${(p) => p.theme.treeBoard.aim.width}px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 8px;

  background-color: ${(p) =>
    p.isSelected
      ? p.theme.treeBoard.aim.backgroundColor.selected
      : p.theme.treeBoard.aim.backgroundColor.initial};

  position: sticky;
  left: 0px;

  color: rgb(222, 200, 200);
  line-height: 1.2em;

  cursor: default;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(p) => p.isRoot && `font-weight: bold; white-space: nowrap;`}
  ${(p) =>
    p.isEditing &&
    p.isRoot &&
    `
      position: fixed;
      white-space: normal;
      min-width: $leafWidth;
      top: 20px;
      left: 8px;
      z-index: 10;
  `}
`

export const Timer = styled.div`
  margin-bottom: 4px;
`

export const Folded = styled.div`
  position: absolute;
  top: -4px;
  right: 8px;
  font-weight: bold;
  font-size: 8px;
`
