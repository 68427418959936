import styled from 'styled-components'

export const Wrapper = styled.div``

export const Label = styled.div`
  width: 400px;
  margin-top: 14px;
  margin-bottom: 8px;
`

export const SelectLabel = styled(Label)`
  margin-top: 0px;
`
export const InputLabel = styled(Label)``

export const Controls = styled.div`
  margin-top: 24px;
  text-align: right;
`

export const ValidationError = styled.div`
  margin: 8px 0px;
  color: red;
`
