import { useState, useEffect, useCallback } from 'react'
import sharedClock from 'services/shared-clock'

function useForceRender() {
  const [forcedRenderTimestamp, setForcedRenderTimestamp] = useState(Date.now())
  const forceRender = useCallback(() => setForcedRenderTimestamp(Date.now()), [])

  return { forcedRenderTimestamp, forceRender }
}

export function useSharedIntervalForcedRender(enabled = true, useRequestAnimationFrame = false) {
  const { forceRender } = useForceRender()

  useEffect(() => {
    enabled
      ? sharedClock.subscribe(forceRender, useRequestAnimationFrame)
      : sharedClock.unsubscribe(forceRender)
    return () => sharedClock.unsubscribe(forceRender)
  }, [enabled])

  return forceRender;
}

export default useForceRender
