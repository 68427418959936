import * as I from 'types'
import { createStore, createEvent } from 'effector'

export const $panels = createStore<I.Panel>({
  settings: false,
})

export const $treeBoardPage = createStore<I.AppState.TreeBoardPage>({
  detailsAimId: null,
})

export const event = {
  toggleSettingsPanel: createEvent(),
  setDetailsAimId: createEvent<I.Model.AimId | null>(),
}

$panels.on(event.toggleSettingsPanel, (s) => ({ ...s, settings: !s.settings }))

$treeBoardPage.on(event.setDetailsAimId, (s, detailsAimId) => ({ ...s, detailsAimId }))
