export const findBy = <T>(records: Record<string, T>) => (comparator: (x: T) => boolean): T | null => {
  for (const key in records) {
    const recordItem = records[key]
    if (comparator(recordItem)) {
      return recordItem
    }
  }

  return null
}

export const pickBy = <T>(records: Record<string, T>) => (
  comparator: (x: T) => boolean
): Record<string, T> => {
  const result: Record<string, T> = {}

  for (const key in records) {
    const recordItem = records[key]
    if (comparator(recordItem)) {
      result[key] = recordItem
    }
  }

  return result
}

export const omit = <T extends object, K extends Array<keyof T>>(obj: T, keys: K): Omit<T, K[number]> => {
  const result: any = {}
  const keysSet = new Set(keys)
  
  for (const key in obj) {
    
    if (keysSet.has(key)) {
      continue
    }
    
    result[key] = obj[key]
  }
  
  return result
}

export const pick = <T extends object, K extends Array<keyof T>>(obj: T, keys: K): Pick<T, K[number]> => {
  const result: any = {}
  const keysSet = new Set(keys)

  for (const key in obj) {
    if (keysSet.has(key)) {
      result[key] = obj[key]
    }

    continue
  }

  return result
}

export const range = <T = number>(n: number, mapper?: (elIndex: number) => T): T[] => {
  const x: T[] = []

  for (let i = 0; i < n; i++) {
    const v = mapper ? mapper(i) : i
    x.push(v as any)
  }

  return x
}

export const isObjEqual = <T extends { [k: string]: unknown }>(a: T, b: T): boolean => {
  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)

  if (aKeys.length !== bKeys.length) return false

  for (const k of aKeys) {
    if (a[k] !== b[k]) return false
  }

  return true
}

export default {
  findBy,
  pickBy,
  omit,
  pick,
  range,
  isObjEqual
}
