import styled from 'styled-components'
import { lighten, transparentize } from 'polished'

import { ThemedScroll } from 'sc'

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
`

const withLighten = lighten(0.05)

export const Body = styled(ThemedScroll)`
  min-width: 300px;
  max-width: 100vw;
  max-height: calc(100vh - 100px);
  border-radius: 8px;
  padding: 16px 24px;
  overflow: auto;

  background-color: ${(x) => transparentize(0.4)(withLighten(x.theme.treeBoard.background.color))};
  color: ${(x) => withLighten(x.theme.general.font.color)};

  font-family: Roboto;
  backdrop-filter: blur(5px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
`
