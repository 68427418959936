/* Component based on source https://github.com/Error-256/react-bezier */
import React, { useState, useRef, useLayoutEffect, useCallback } from 'react'
import './Bezier.scss'

import { isNotNullable } from 'utils/checkers'
import * as utils from './Bezier.utils'

type Props = {
  treeScrollElId?: string
  children: React.ReactNode | Array<React.ReactNode>
  settings: BezierSetting[]
  arrow?: boolean

  /// Hide lines `BezierSetting.positions.end` of which is
  /// positioned to the left of `BezierSetting.positions.start`
  forbidToShowFromLeftToRightLines?: boolean
}

export type Direction = 'top' | 'bottom' | 'left' | 'right'
export type Coordinate = { x: number; y: number }

export type Line = {
  id: string
  cord0: Coordinate
  cord1: Coordinate
  cord2: Coordinate
  cord3: Coordinate
  style: string
}

type Position = {
  side: Direction
  indent?: number
}

export type BezierSetting = {
  from: string
  to: string
  positions: {
    start: Position
    end: Position
  }
}

const SVGBezierLine = React.memo(function SVGBezierLine(p: Line & { hash: string; index: number }) {
  return (
    <svg className={'bezier-line'}>
      <path
        id={`path-${p.hash}-${p.index}`}
        d={`M${p.cord0.x} ${p.cord0.y} C ${p.cord1.x} ${p.cord1.y}, ${p.cord2.x} ${p.cord2.y}, ${p.cord3.x} ${p.cord3.y}`}
        className={p.style}
        // TODO make configurable
        style={{
          stroke: 'rgb(67, 50, 50)',
          opacity: '0.5',
        }}
      />
    </svg>
  )
})

const MemoizedReactBezierLines = React.memo(function ReactBezierLines(p: {
  containerEl: HTMLDivElement
  settings: BezierSetting[]
  hash: string
  treeScrollElId?: string

  /// Hide lines `BezierSetting.positions.end` of which is
  /// positioned to the left of `BezierSetting.positions.start`
  forbidToShowFromLeftToRightLines?: boolean
}) {
  const [lines, setLines] = useState<Line[]>(() => [])

  const update = useCallback(() => {
    const lines = p.settings
      .map((setting) =>
        utils.getLine({
          setting,
          container: p.containerEl,
          hash: p.hash,
          forbidToShowFromLeftToRightLines: p.forbidToShowFromLeftToRightLines,
        })
      )
      .filter(isNotNullable)

    setLines(lines)
  }, [p.settings, p.hash])

  useLayoutEffect(() => {
    setTimeout(update, 0)

    if (p.treeScrollElId) {
      const scrollEl = document.getElementById(p.treeScrollElId)

      const handleScroll = () => window.requestAnimationFrame(() => update())

      if (scrollEl) {
        scrollEl.addEventListener('scroll', handleScroll)

        return () => scrollEl.removeEventListener('scroll', handleScroll)
      }
    }
  }, [update, p.treeScrollElId])

  useLayoutEffect(() => {
    const observer = new MutationObserver(() => window.requestAnimationFrame(update))

    observer.observe(p.containerEl, {
      attributes: true,
      characterData: true,
      subtree: true,
      childList: true,
    })

    return () => {
      observer.disconnect()
    }
  }, [update])

  return (
    <>
      {lines.map((line, i) => (
        <SVGBezierLine {...line} index={i} hash={p.hash} key={line.id} />
      ))}
    </>
  )
})

export default function ReactBezierContainer(p: Props) {
  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>(null)
  const hashRef = useRef(utils.makeHash())

  return (
    <div
      ref={setContainerEl}
      className={`react-bezier-lines-${hashRef.current}`}
      style={{ position: 'relative', height: 'inherit' }}
    >
      {p.children}
      {containerEl && (
        <MemoizedReactBezierLines
          treeScrollElId={p.treeScrollElId}
          settings={p.settings}
          containerEl={containerEl}
          hash={hashRef.current}
          forbidToShowFromLeftToRightLines={p.forbidToShowFromLeftToRightLines}
        />
      )}
    </div>
  )
}
