import { requestAPIFactory } from 'utils/network'
import { manageAuth } from 'local-storage'
import { EventPayload, EventResponse } from '../../server/src/types'

const http = requestAPIFactory(
  process.env.NODE_ENV === 'development' ? 'http://localhost:3006/api' : '/api',
  {
    getAuthHeader: () => manageAuth.get()?.token || null,
    on401Status: () => {
      manageAuth.set(null)
      location.reload()
    },
  }
)

/// One can extend/check API endpoints at `src/rest-server.ts`

export default {
  // Authorization

  auth: {
    signUp: (p: EventPayload.Auth.SignUp) => http.post<EventResponse.Auth.SignUp>('/sign-up', p),
    signIn: (p: EventPayload.Auth.SignIn) => http.post<EventResponse.Auth.SignIn>('/sign-in', p),
  },

  // Fetch

  fetch: {
    aims: (p: EventPayload.Fetch.Aims) => http.get<EventResponse.Fetch.Aims>('/aims', p),
    timePeriods: (p: EventPayload.Fetch.TimePeriods) =>
      http.get<EventResponse.Fetch.TimePeriods>('/time-periods', p),
  },

  // Merge (create/update)

  merge: {
    aims: (p: EventPayload.Merge.Aims) => http.merge<EventResponse.Merge.Aims>('/aims', p),
    timePeriods: (p: EventPayload.Merge.TimePeriods) =>
      http.merge<EventResponse.Merge.TimePeriods>('/time-periods', p),
  },

  // Delete

  delete: {
    timePeriod: (p: EventPayload.Delete.TimePeriod) =>
      http.delete<EventPayload.Delete.TimePeriod>('/time-period', p),
  },
}
