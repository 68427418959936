import styled from 'styled-components'

export const FixedAndCentralized = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

export const ModalBody = styled.div`
  padding: 24px;
  background-color: #fff;
`

export const ThemedScroll = styled.div`
  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(43, 43, 43);
    border-radius: 8px;
  }

  ::-webkit-scrollbar-corner {
    display: none;
  }
`
