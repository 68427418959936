import React from 'react'

import AimItem, { useAimItem } from 'components/AimItem'
import RootAimTimers from 'components/RootAimTimers'
import Modal, { useModal } from 'components/Modal'
import HotkeysLegend from 'components/HotkeysLegend'

import { manageAuth } from 'local-storage'

import * as El from './TopPanel.styles'
import * as I from 'types'

const RootAimWithEffects = React.memo(function RootAimWithEffects(p: {
  rootAimId: I.RootAimId
  id: I.Model.AimId
}) {
  const e = useAimItem(p.id, p.rootAimId)

  return (
    <AimItem
      id={p.id}
      name={e.name || ''}
      alwaysShowTimer={true}
      isTracking={e.isTracking}
      isActive={e.isActive}
      isSelected={e.isSelected}
      isRoot={p.rootAimId === p.id}
      isFirstRootChild={e.isFirstRootChild}
      isFolded={e.isFolded}
      hasChildren={e.hasChildren}
      dropRef={e.dropRef}
      scrollIntoViewElRef={e.scrollIntoViewElRef}
      modifyPhase={e.modifyPhase}
      onOpenAimDetails={e.onOpenAimDetails}
      onSelectAim={e.onSelectAim}
      onEditingComplete={e.onEditingComplete}
      onRemovingConfirmed={e.onRemovingConfirmed}
      onRemovingDenied={e.onRemovingDenied}
      onCreatingComplete={e.onCreatingComplete}
      onCreatingCancelled={e.onCreatingCancelled}
    />
  )
})

export default React.memo(function TopPanel(p: { rootAimId: I.RootAimId }) {
  const helpModal = useModal()

  const logout = () => {
    manageAuth.set(null)
    window.location.href = '/login'
  }

  return (
    <El.TopPanel>
      <El.RootAim>
        <RootAimWithEffects rootAimId={p.rootAimId} id={p.rootAimId} />
      </El.RootAim>

      <El.Timers>
        <RootAimTimers rootAimId={p.rootAimId} />
      </El.Timers>

      <El.RightCornerBlock>
        <El.Logout onClick={logout}>Logout</El.Logout>
        <El.Help onClick={helpModal.show}>Help</El.Help>
        {helpModal.isOpen && (
          <Modal onOverlayClick={helpModal.hide}>
            <HotkeysLegend />
          </Modal>
        )}
      </El.RightCornerBlock>
    </El.TopPanel>
  )
})
