export const checkIsValidProps = <T extends { [k: string]: any }>(
  target: T,
  checker: Partial<T>
): boolean => {
  const checkResults: boolean[] = Object.keys(checker).map(
    (k) => target[k] === checker[k]
  )
  return checkResults.includes(false) === false
}

export function isEmpty(x: any): boolean {
  if (typeof x === 'number' && isNaN(x)) {
    return true
  }

  if (x === undefined || x === null) {
    return true
  }

  if (typeof x === 'string' && x.length === 0) {
    return true
  }

  if (Array.isArray(x) && x.length === 0) {
    return true
  }

  return false
}

export function isNotNullable<T>(it: T): it is NonNullable<T> {
  return it !== null && it !== undefined;
}
