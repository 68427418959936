import React from 'react'
import cn from 'classnames'
import { useStore } from 'effector-react'

import { Stop, PlayArrow } from '@material-ui/icons'
import './Timer.scss'

import { useSharedIntervalForcedRender } from 'hooks/useForceRender'
import * as timeTracker from 'features/timeTracker'

import * as I from 'types'

type Props = {
  isTracking: boolean
  spentTimeMilliseconds: number
  bigSize?: boolean

  onTrackingStart: () => void
  onTrackingStop: () => void
}

const SECOND_MS = 1000
const MINUTE_MS = SECOND_MS * 60
const HOUR_MS = MINUTE_MS * 60

export function formattedMS(milliseconds: number) {
  const hours = Math.floor(milliseconds / HOUR_MS)
  const minutes = Math.floor((milliseconds - hours * HOUR_MS) / MINUTE_MS)
  const seconds = Math.floor((milliseconds - hours * HOUR_MS - minutes * MINUTE_MS) / SECOND_MS)

  const f = (x: number) => {
    const str = `${x}`
    return str.length === 1 ? `0${str}` : str
  }

  return `${f(hours)}:${f(minutes)}:${f(seconds)}`
}

export default function Timer(p: Props) {
  const { bigSize } = p

  return (
    <div className={`Timer ${cn({ bigSize })}`}>
      <div className={`Timer__spentTime ${cn({ bigSize })}`}>{formattedMS(p.spentTimeMilliseconds)}</div>

      {p.isTracking == false && (
        <PlayArrow className={`Timer__button ${cn({ bigSize })}`} onClick={p.onTrackingStart} />
      )}
      {p.isTracking && <Stop className={`Timer__button ${cn({ bigSize })}`} onClick={p.onTrackingStop} />}
    </div>
  )
}

export function useTimeTracker(linkedItemId: I.Model.AimId) {
  const state = useStore(timeTracker.$timeTrackerStore)

  const timePeriodIds = state.timePeriodIdsByLinkedEntityId.get(linkedItemId) || []
  const timePeriods = [...state.timePeriodById.pick(timePeriodIds).values()]

  const isTracking = state.trackingAimId == linkedItemId
  const spentTimeMilliseconds = calculateSpentTimeMS(timePeriods)

  useSharedIntervalForcedRender(isTracking)

  return {
    isTracking,
    spentTimeMilliseconds,
    handle: {
      tracking: {
        start: () => timeTracker.event.tracking.start({ trackingAimId: linkedItemId }),
        stop: timeTracker.event.tracking.stop,
      },
    },
  }
}

export function calculateSpentTimeMS(periods: I.Model.TimePeriod[]) {
  return periods.reduce((acc, period) => acc + ((period.to || Date.now()) - period.from), 0)
}
