import { useMemo } from 'react'
import { useStore } from 'effector-react'
import { $settings } from 'features/settings'

export default function useTheme() {
  const settings = useStore($settings)
  const theme = useMemo(() => settings.profiles[settings.selectedProfile], [settings])

  return theme
}
