type Callback = () => void

function sharedClock(intervalTimeout: number) {
  let subs: Callback[] = []
  let subsWithAnimationFrame: Callback[] = []

  const subscribe = (cb: Callback, useRequestAnimationFrame = false) =>
    useRequestAnimationFrame //
      ? subsWithAnimationFrame.push(cb)
      : subs.push(cb)

  const unsubscribe = (x: Callback) => {
    subs = subs.filter((cb) => cb !== x)
    subsWithAnimationFrame = subsWithAnimationFrame.filter((cb) => cb !== x)
  }

  setInterval(() => {
    subs.forEach((cb) => cb())
    window.requestAnimationFrame(() => subsWithAnimationFrame.forEach((cb) => cb()))
  }, intervalTimeout)

  return { subscribe, unsubscribe }
}

export const eachSecondClock = sharedClock(1000)
export const eachMinuteClock = sharedClock(1000 * 60)

export default eachSecondClock
