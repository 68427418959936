import React from 'react'
import { Link } from 'react-router-dom'
import { TextField, Button, LinearProgress } from '@material-ui/core'
import './LoginForm.scss'

import * as cfg from './LoginForm.cfg'
import useDynamicForm, { FormSchema } from 'hooks/useDynamicForm'

type Props = {
  schema: FormSchema
  handleSubmit: (values: { [k in keyof typeof cfg.FIELD_NAMES]: string }) => Promise<void>
}

export default function LoginForm(p: Props) {
  const form = useDynamicForm(p)
  const [loginFormFieldGroup] = form.fieldGroups

  return (
    <form className="LoginForm" onSubmit={form.handleSubmit}>
      {loginFormFieldGroup.fields.map((f) => {
        if (f.type !== 'string') {
          return null
        }

        return (
          <div className="LoginForm__field" key={f._uid}>
            <TextField
              error={!!f.error}
              disabled={form.isSubmitting}
              required={f.required}
              autoFocus={f._uid === cfg.FIELD_NAMES.username}
              type={f.inputType}
              onChange={(e) => f.onChange(e.target.value)}
              value={f.value}
              label={f.placeholder}
              helperText={f.error}
              fullWidth={true}
            />
          </div>
        )
      })}

      {form.submittingError.message && (
        <div className="LoginForm__error-msg">{form.submittingError.message}</div>
      )}
      {!form.submittingError.message && <div className="LoginForm__error-msg-placeholder" />}

      <div className="LoginForm__submit-btn">
        <Button
          variant="contained"
          size="large"
          type="submit"
          color="primary"
          fullWidth={true}
          disabled={!form.isValid || form.isSubmitting || form.submittingError.isError}
        >
          Login
        </Button>
        {form.isSubmitting && <LinearProgress />}
      </div>

      <div className="LoginForm__sign-up-link">
        <Link to="/sign-up">Sign up</Link>
      </div>
    </form>
  )
}
