export function withEitherColorChange(callback: (color: string) => void) {
  return (colorRGBA: string) => {
    const RGBA = colorRGBA
      .replace('rgba(', '')
      .replace(')', '')
      .split(',')
      .map((x, idx) => (idx === 3 ? parseFloat(x.trim()) : parseInt(x.trim(), 10)))

    if (RGBA.length < 3) return

    if (RGBA.length === 3) {
      RGBA.push(1)
    }

    for (const x of RGBA) {
      if (isNaN(x)) {
        return
      }
    }

    const [r, g, b, a] = RGBA

    callback(`rgba(${r}, ${g}, ${b}, ${Math.min(a, 1)})`)
  }
}
