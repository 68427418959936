import * as d from 'date-fns/fp'
import { range } from '../utils/record'

type Range = { from: number; to: number }
type TimePeriod = { from: number; to?: number }

export const SECOND_MS = 1000
export const MINUTE_MS = SECOND_MS * 60
export const HOUR_MS = MINUTE_MS * 60
export const DAY_MS = HOUR_MS * 24
export const WEEK_MS = DAY_MS * 7

export function getCurrentDayBoundaries(): { start: number; end: number } {
  const start = new Date()
  const end = new Date()

  start.setHours(0, 0, 0, 0)
  end.setHours(23, 59, 59, 999)

  return { start: start.valueOf(), end: end.valueOf() }
}

export function getOverallSpentTime(timePeriods: TimePeriod[]) {
  return timePeriods.reduce((acc, x) => (x.to || Date.now()) - x.from + acc, 0)
}

export function getTodaySpentTime(timePeriods: TimePeriod[]): number {
  const nowTs = Date.now()
  const dayBoundaries = getCurrentDayBoundaries()

  return timePeriods
    .filter((period) => {
      if (!period) return false
      if (!period.to) return true
      if (period.to <= dayBoundaries.end && period.to >= dayBoundaries.start) return true

      return false
    })
    .reduce((acc, period) => acc + ((period.to || nowTs) - Math.max(period.from, dayBoundaries.start)), 0)
}

export function isRangesOverlap(a: Range, b: Range): boolean {
  if (a.to >= b.from && a.to <= b.to) return true
  if (b.to >= a.from && b.to <= a.to) return true

  return false
}

export function getDistanceBetweenRanges(a: Range, b: Range): number {
  if (isRangesOverlap(a, b)) return 0
  const [first, second] = [a, b].sort((a, b) => a.from - b.from)

  return second.from - first.to
}

/**
 * @param orderedTimePeriods should ordered in newest first order (by `orderedTimePeriods.from` timestamp)
 * @param maxGap allowed time gap between periods that will be counted as continuous time
 */
export function getSpentTimeContinuouslyFromNow(orderedTimePeriods: TimePeriod[], maxGap = 5000): number {
  const now = Date.now()
  const mostResentPeriod = orderedTimePeriods[0]

  if (!mostResentPeriod) return 0

  const isContinuous = maxGap >= now - (mostResentPeriod.to || now)

  if (!isContinuous) return 0

  const firstPeriod = orderedTimePeriods[0]
  let result = (firstPeriod.to || now) - firstPeriod.from

  if (orderedTimePeriods.length === 1) {
    return result
  }

  for (let i = 1; i < orderedTimePeriods.length; i++) {
    const a = orderedTimePeriods[i]
    const b = orderedTimePeriods[i - 1]

    const d = getDistanceBetweenRanges({ from: a.from, to: a.to || now }, { from: b.from, to: b.to || now })

    if (d > maxGap) return result

    result += (a.to || a.from) - a.from
  }

  return result
}

export function formattedMS(milliseconds: number) {
  const hours = Math.floor(milliseconds / HOUR_MS)
  const minutes = Math.floor((milliseconds - hours * HOUR_MS) / MINUTE_MS)
  const seconds = Math.floor((milliseconds - hours * HOUR_MS - minutes * MINUTE_MS) / SECOND_MS)

  const f = (x: number) => {
    const str = `${x}`
    return str.length === 1 ? `0${str}` : str
  }

  return `${f(hours)}:${f(minutes)}:${f(seconds)}`
}

export function groupByDay<T extends { from: number; to?: number }>(
  items: T[]
): Array<{
  dayTimestamp: number
  timeSpent: number
  items: T[]
}> {
  if (items.length === 0) {
    return []
  }

  const sortedItems = [...items].sort((a, b) => a.from - b.from)

  const startOf = sortedItems[0].from
  const endOf = sortedItems[sortedItems.length - 1].from
  const dayTimestamps = getStartOfDaysRange(startOf, endOf)

  return dayTimestamps
    .map((ts, idx) => {
      const nextTs = dayTimestamps[idx + 1] || Infinity
      const items = sortedItems.filter((m) => m.from >= ts && m.from < nextTs)
      const now = Date.now()
      const timeSpent = items.map(({ from, to }) => (to || now) - from).reduce((a, b) => a + b, 0)

      return {
        timeSpent,
        items,
        dayTimestamp: ts,
      }
    })
    .filter((x) => x.items.length > 0)
}

export const getStartOfDaysRange = (startOf: number, endOf: number) => {
  const startOfDayTs = new Date(startOf).setHours(0, 0, 0, 0)
  const endOfDayTs = new Date(endOf).setHours(0, 0, 0, 0) + DAY_MS

  const days = (endOfDayTs - startOfDayTs) / DAY_MS
  const daysRange = range(days)
  const result = daysRange.map((idx) => idx * DAY_MS + startOfDayTs)

  return result
}

export const format = {
  date: d.format('yyyy.MM.dd'),
  fullDayOfWeek: d.format('EEEE'),
}

export const debounce = <T extends (...args: any) => any>(func: T, wait: number): T & { cancel: () => void } => {
  let timer: NodeJS.Timeout

  const debouncedFn = () => {
    clearTimeout(timer)
    timer = setTimeout(func, wait)
  }

  debouncedFn.cancel = () => clearTimeout(timer)

  return debouncedFn as any
}
