import qp from 'utils/routing'
import * as feature from './index'

import * as I from 'types'

export const withAllowedModifyPhase = <T = (...x: any) => void>(
  callback: T,
  allowedPhases: I.ModifyPhase[]
): T => {
  // @ts-ignore
  return (...x: any) => {
    const modifyPhase = feature.treeBoard.$controlPanel.getState().modifyPhase
    if (allowedPhases.includes(modifyPhase) === false) return () => undefined

    // @ts-ignore
    return callback(...x)
  }
}

const toggleTrackingTime = withAllowedModifyPhase((e: KeyboardEvent) => {
  const treeBoardStore = feature.treeBoard.$treeBoardStore.getState()
  const selectedAimId = treeBoardStore.controlPanel.selectedAimId

  if (!selectedAimId) return

  e.preventDefault()

  const trackingAimId = feature.timeTracker.$trackingAimId.getState()

  if (trackingAimId === selectedAimId) {
    feature.timeTracker.event.tracking.stop()
    return
  }

  feature.timeTracker.event.tracking.start({ trackingAimId: selectedAimId })
}, [null])

const openAimDetails = withAllowedModifyPhase(() => {
  const treeBoardStore = feature.treeBoard.$treeBoardStore.getState()
  const selectedAimId = treeBoardStore.controlPanel.selectedAimId

  if (!selectedAimId) return

  qp.patch({ detailsAimId: selectedAimId })
  feature.page.event.setDetailsAimId(selectedAimId)
}, [null, 'cutting'])

const closeAimDetails = () => {
  qp.patch({ detailsAimId: null })
  feature.page.event.setDetailsAimId(null)
}

export default { toggleTrackingTime, withAllowedModifyPhase, openAimDetails, closeAimDetails }
