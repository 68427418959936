// based on https://github.com/MrFranke/effector-persist/blob/master/src/index.ts
import { merge, cloneDeep } from 'lodash'
import { createEvent, Store } from 'effector'

type PersistConfig = {
  key?: string
  expire?: number
}

const defaultConfig = {
  key: 'EFFECTOR_PERSIST',
}

export const withPersist = <State>(store: Store<State>, config: PersistConfig = defaultConfig) => {
  const name = store.shortName
  const { key, expire } = config

  const initialState = cloneDeep(store.getState())

  const persistKey = `${key}:${name}`
  const rehydrate = createEvent('@PERSIST/REHYDRATE')

  if (expire && isExpired(expire)) {
    localStorage.removeItem(persistKey)
  }

  const snapshot = localStorage.getItem(persistKey)

  if (snapshot) {
    store.on(rehydrate, () => ({ ...merge(initialState, JSON.parse(snapshot)) }))
    rehydrate()
  }

  store.watch((state) => {
    localStorage.setItem(persistKey, JSON.stringify(state))
  })

  return store
}

const isExpired = (expire: number) => expire < Date.now()

export default withPersist
