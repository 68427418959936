import React, { useCallback, useState } from 'react'
import './ColorInput.scss'

import { RgbaStringColorPicker } from 'react-colorful'

import { withEitherColorChange } from './ColorInput.utils'

type Props = {
  color: string // rgba color like: rgba(23, 16, 16, 1)
  onChange: (color: string) => void
}

const style: React.CSSProperties = {
  color: '#000',
}

export default function ColorInput(p: Props) {
  const [shouldShowColorPicker, setShouldShowColorPicker] = useState(false)
  const toggleColorPicker = () => setShouldShowColorPicker((x) => !x)

  const onChange = useCallback(withEitherColorChange(p.onChange), [p.onChange])

  return (
    <div className="ColorInput" style={style}>
      <div className="ColorInput__input">
        <div className="ColorInput__color" style={{ backgroundColor: p.color }} onClick={toggleColorPicker} />
        <input value={p.color} onChange={(e) => onChange(e.target.value)} />
      </div>

      {shouldShowColorPicker && (
        <div className="ColorInput__colorPicker">
          <div className="ColorInput__overlay" onClick={toggleColorPicker} />
          <RgbaStringColorPicker color={p.color} onChange={p.onChange} />
        </div>
      )}
    </div>
  )
}
