import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-left: 33px;
    margin-right: 33px;
  }
`

export const TopPanelTimer = styled.div`
  width: 120px;
`

export const TopPanelTimerLabel = styled.div`
  font-size: 12px;
`

export const TopPanelTimerTime = styled.div`
  margin-top: 4px;
  font-size: 24px;
  font-weight: bold;
`
