import { createStore, createEvent, createEffect } from 'effector'

import { fromArrToMapByKey } from 'utils/map'
import http from 'http-client'
import { manageAuth, FoldedAimsLocalStorage } from 'local-storage'

import * as U from 'utils/tree'

import * as timeTracker from './timeTracker'
import { getCurrentSettingsProfile } from './settings'

import * as I from 'types'

const DRAFT_AIM_NAME = '<<UNIQUE_NAME>>'

export const $treeBoardStore = createStore<I.AppState.TreeBoard>({
  fetched: false,
  aims: new Map(),
  controlPanel: {
    rootAimId: null,
    selectedAimId: null,
    cutAimId: null,
    prevSelectedChildren: null,
    modifyPhase: null,
  },
})

export const $foldedAims = createStore<Map<I.RootAimId, Set<I.Model.AimId>>>(FoldedAimsLocalStorage.read())
export const $aimById = $treeBoardStore.map((s) => s.aims)
export const $controlPanel = $treeBoardStore.map((s) => s.controlPanel)

export const event = {
  interface: {
    selectRootAimId: createEvent<I.RootAimId>(),
    selectTrackingAim: createEvent(),
  },
  aim: {
    focus: createEvent(),
    focusParentAim: createEvent(),
    remove: createEvent<I.Model.AimId>(),
    foldToggle: createEvent(),
    move: createEvent<{
      aimOnly:
        | true // move only target Aim and assign it's children to it's parent Aim
        | false // move aim with all it's children (move aim branch)
      options:
        | { type: 'order'; direction: I.Direction } // change Aim display order
        | { type: 'parent'; aimId: I.Model.AimId; parentId: I.Model.AimId } // change Aim parent
    }>(),

    moveSelectionPointerToItemId: createEvent<I.Model.AimId>(),
    moveSelectionPointer: createEvent<I.Direction>(),
    moveSelectionPointerByTabKey: createEvent<I.TabKeySelection>(),

    // target must be selected or modify events will be ignored
    modify: {
      // we support cutting tree branch, not one specific item for now
      cutting: { start: createEvent(), cancel: createEvent(), complete: createEvent() },
      editing: {
        start: createEvent(),
        cancel: createEvent(),
        complete: createEvent<{ updatedName: string }>(),
      },
      removing: { start: createEvent(), cancel: createEvent(), complete: createEvent() },
      creating: {
        start: createEvent<{ creationType: 'parentCreating' | 'childCreating' }>(),
        cancel: createEvent(),
        complete: createEvent<Partial<I.Model.Aim> & { name: string }>(),
      },
    },
  },
}

export const effect = {
  fetchBoard: createEffect(http.fetch.aims),
  updateBoard: createEffect(http.merge.aims),
}

export const constants = { DRAFT_AIM_NAME }
export const utils = { ...U, isFirstRootChild, copySelectedAimNameToClipboard }

/* Post effect reaction */

$treeBoardStore.on(
  effect.fetchBoard.doneData,
  (s, aims): I.AppState.TreeBoard => ({
    ...s,
    fetched: true,
    controlPanel: { ...s.controlPanel },
    aims: fromArrToMapByKey(aims, 'id'),
  })
)

/* Generic tree item event subscriptions */

$treeBoardStore
  .on(event.aim.remove, (s, aimId) => {
    const aimById = s.aims
    const targetAim = aimById.get(aimId)

    if (!targetAim) throw Error('targetAim is missing')

    const dependantAims = U.getDepSet(s.aims, targetAim.id, 'children')

    dependantAims.forEach((aim, aimId) => {
      dependantAims.set(aimId, {
        ...aim,
        isArchived: true,
        children: [],
        archivedChildren: [...aim.archivedChildren, ...aim.children],
      })
    })

    const parentAim = targetAim.parent ? aimById.get(targetAim.parent) : undefined

    if (parentAim) {
      const children = parentAim.children.filter((x) => x !== targetAim.id)
      const archivedChildren = [...parentAim.archivedChildren, targetAim.id]

      dependantAims.set(parentAim.id, { ...parentAim, children, archivedChildren })
    }

    effect.updateBoard([...dependantAims.values()])

    return {
      ...s,
      aims: s.aims.merge(dependantAims).clone(),
      controlPanel: { ...s.controlPanel, modifyPhase: null, selectedAimId: parentAim?.id || null },
    }
  })
  .on(event.aim.move, (s, { options }) => {
    switch (options.type) {
      case 'order': {
        const { direction } = options
        const selectedAimId = s.controlPanel.selectedAimId

        if (s.controlPanel.modifyPhase) return
        if (!selectedAimId) return

        const correctedDirection = U.getCorrectDirection(direction, {
          aims: s.aims,
          foldedAims: $foldedAims.getState(),
          selectedAimId: s.controlPanel.selectedAimId,
          rootAimId: s.controlPanel.rootAimId,
          showAimsWithoutChildrenVerticalList:
            getCurrentSettingsProfile().treeBoard.behaviour.showAimsWithoutChildrenVerticalList,
        })

        const updatedItems = U.changeAimOrder(s.aims, selectedAimId, correctedDirection || direction)

        effect.updateBoard([...updatedItems.values()])

        return {
          ...s,
          aims: s.aims.merge(updatedItems).clone(),
        }
      }
      case 'parent': {
        const { aimId, parentId } = options

        // TODO disable because behaviour is not clear
        window.alert('Moving Aim to upper level is disabled, use D too cut and P to past instead')
        return

        const updatedItems = U.changeAimParent(s.aims, aimId, parentId)

        effect.updateBoard([...updatedItems.values()])

        return {
          ...s,
          aims: s.aims.merge(updatedItems).clone(),
        }
      }
    }
  })
  .on(
    event.aim.moveSelectionPointer,
    withPhaseRequirements(
      (s, { payload: direction }) => {
        const selectedAimId = s.controlPanel.selectedAimId
        const rootAimId = s.controlPanel.rootAimId
        const settings = getCurrentSettingsProfile()

        if (!selectedAimId || !rootAimId) return

        const correctedDirection = U.getCorrectDirection(direction, {
          aims: s.aims,
          foldedAims: $foldedAims.getState(),
          selectedAimId: s.controlPanel.selectedAimId,
          rootAimId: s.controlPanel.rootAimId,
          showAimsWithoutChildrenVerticalList:
            getCurrentSettingsProfile().treeBoard.behaviour.showAimsWithoutChildrenVerticalList,
        })

        const updatedControlPanel = U.getNextSelectedAimControlPanelState(
          s,
          correctedDirection || direction,
          settings.treeBoard.behaviour.usePreviousSelectedChildren,
          $foldedAims.getState()
        )

        if (!updatedControlPanel) return

        return { ...s, controlPanel: { ...s.controlPanel, ...updatedControlPanel } }
      },
      [null, 'cutting']
    )
  )
  .on(event.aim.moveSelectionPointerByTabKey, (s) => {
    const selectedAimId = s.controlPanel.selectedAimId
    const rootAimId = s.controlPanel.rootAimId
    if (
      selectedAimId ||
      !rootAimId ||
      (s.controlPanel.modifyPhase && s.controlPanel.modifyPhase !== 'cutting')
    ) {
      return
    }
    return { ...s, controlPanel: { ...s.controlPanel, selectedAimId: rootAimId } }
  })
  .on(event.aim.moveSelectionPointerToItemId, (s, itemId) => ({
    ...s,
    controlPanel: { ...s.controlPanel, selectedAimId: itemId },
  }))

/* Modifying phase subscriptions */

function withPhaseRequirements<S extends I.AppState.TreeBoard, P>(
  eventReaction: (s: S, p: { payload: P; selectedId: I.Model.AimId; modifyPhase: I.ModifyPhase }) => S | void,
  allowedPhases: I.ModifyPhase[] = [null]
) {
  return (s: S, payload: P) => {
    const selectedId = s.controlPanel.selectedAimId
    const modifyPhase = s.controlPanel.modifyPhase

    if (!selectedId || !allowedPhases.includes(s.controlPanel.modifyPhase)) {
      return
    }

    return eventReaction(s, { payload, selectedId, modifyPhase })
  }
}

/* Interface */

$treeBoardStore.on(event.interface.selectRootAimId, (s, rootAimId) => ({
  ...s,
  controlPanel: { ...s.controlPanel, rootAimId },
}))

/* cutting */

$treeBoardStore
  .on(
    event.aim.modify.cutting.start,
    withPhaseRequirements(
      (s, { selectedId }) => ({
        ...s,
        controlPanel: { ...s.controlPanel, cutAimId: selectedId, modifyPhase: 'cutting' },
      }),
      [null, 'cutting']
    )
  )
  .on(
    event.aim.modify.cutting.cancel,
    withPhaseRequirements(
      (s) => ({
        ...s,
        controlPanel: { ...s.controlPanel, cutAimId: null, modifyPhase: null },
      }),
      ['cutting']
    )
  )
  .on(
    event.aim.modify.cutting.complete,
    withPhaseRequirements(
      (s, { selectedId }) => {
        const cutAimId = s.controlPanel.cutAimId
        if (!cutAimId) return

        const destinationAimId = selectedId

        /* Preventing the case when we trying to past branch to one of its members */
        const shouldPreventRecursion = U.getDepSet(s.aims, cutAimId, 'children').get(destinationAimId)
        if (shouldPreventRecursion) return

        const modifiedAims = U.changeAimParent(s.aims, cutAimId, destinationAimId)

        effect.updateBoard([...modifiedAims.values()])

        return {
          ...s,
          aims: s.aims.merge(modifiedAims).clone(),
          controlPanel: { ...s.controlPanel, modifyPhase: null, cutAimId: null },
        }
      },
      ['cutting']
    )
  )

/* editing */

$treeBoardStore
  .on(
    event.aim.modify.editing.start,
    withPhaseRequirements(
      (s) => ({
        ...s,
        controlPanel: { ...s.controlPanel, modifyPhase: 'editing' },
      }),
      [null]
    )
  )
  .on(
    event.aim.modify.editing.cancel,
    withPhaseRequirements(
      (s) => ({ ...s, controlPanel: { ...s.controlPanel, modifyPhase: null } }),
      ['editing']
    )
  )
  .on(
    event.aim.modify.editing.complete,
    withPhaseRequirements(
      (s, { payload: { updatedName }, selectedId }) => {
        const selectedItem = s.aims.get(selectedId)!
        const updatedItem: I.Model.Aim = { ...selectedItem, name: updatedName }

        effect.updateBoard([updatedItem])

        return {
          ...s,
          aims: s.aims.set(updatedItem.id, updatedItem).clone(),
          controlPanel: { ...s.controlPanel, modifyPhase: null },
        }
      },
      ['editing']
    )
  )

/* creating */

$treeBoardStore
  .on(
    event.aim.modify.creating.start,
    withPhaseRequirements(
      (s, { selectedId, payload: { creationType } }) => {
        const parent = s.aims.get(selectedId)!
        const auth = manageAuth.get()

        if (!auth) return

        switch (creationType) {
          case 'childCreating': {
            const draftItem = U.generateNewAim({ name: DRAFT_AIM_NAME, parent: parent.id }, auth.id)

            const updatedParent: I.Model.Aim = { ...parent, children: [draftItem.id, ...parent.children] }

            const aims = s.aims //
              .set(updatedParent.id, updatedParent)
              .set(draftItem.id, draftItem)
              .clone()

            return {
              ...s,
              aims,
              controlPanel: { ...s.controlPanel, modifyPhase: creationType, selectedAimId: draftItem.id },
            }
          }

          case 'parentCreating': {
            const grandParentId = parent.parent

            if (!grandParentId) return s

            const grandParent = s.aims.get(grandParentId)!

            const draftItem = U.generateNewAim(
              { name: DRAFT_AIM_NAME, parent: grandParent.id, children: [parent.id] },
              auth.id
            )

            const parentIdx = grandParent.children.indexOf(parent.id)

            grandParent.children[parentIdx] = draftItem.id

            const updatedGrandParent: I.Model.Aim = { ...grandParent, children: [...grandParent.children] }
            const updatedParent: I.Model.Aim = { ...parent, parent: draftItem.id }

            const aims = s.aims
              .set(updatedGrandParent.id, updatedGrandParent)
              .set(updatedParent.id, updatedParent)
              .set(draftItem.id, draftItem)
              .clone()

            return {
              ...s,
              aims,
              controlPanel: { ...s.controlPanel, modifyPhase: creationType, selectedAimId: draftItem.id },
            }
          }
        }
      },
      [null]
    )
  )
  .on(
    event.aim.modify.creating.cancel,
    withPhaseRequirements(
      (s, { selectedId, modifyPhase }) => {
        const draftAim = s.aims.get(selectedId)!
        const parentAimId = draftAim.parent

        if (!parentAimId) return s

        switch (modifyPhase) {
          case 'childCreating': {
            const parentAim = s.aims.get(parentAimId)!
            const updatedParentAim: I.Model.Aim = {
              ...parentAim,
              children: parentAim.children.filter((id) => id !== draftAim.id),
            }

            const aims = s.aims //
              .set(updatedParentAim.id, updatedParentAim)
              .omit([draftAim.id])
              .clone()

            return {
              ...s,
              aims,
              controlPanel: { ...s.controlPanel, modifyPhase: null, selectedAimId: parentAim.id },
            }
          }
          case 'parentCreating': {
            const grandParentId = draftAim.parent
            const [parentId] = draftAim.children

            if (!grandParentId || !parentId) return s

            const grandParent = s.aims.get(grandParentId)!
            const parent = s.aims.get(parentId)!

            const parentIdx = grandParent.children.indexOf(draftAim.id)

            grandParent.children[parentIdx] = parent.id

            const updatedParent: I.Model.Aim = { ...parent, parent: grandParent.id }
            const updatedGrandParent: I.Model.Aim = { ...grandParent, children: [...grandParent.children] }

            const aims = s.aims
              .set(updatedParent.id, updatedParent)
              .set(updatedGrandParent.id, updatedGrandParent)
              .omit([draftAim.id])
              .clone()

            return {
              ...s,
              aims,
              controlPanel: { ...s.controlPanel, modifyPhase: null, selectedAimId: parent.id },
            }
          }
          default: {
            return s
          }
        }
      },
      ['childCreating', 'parentCreating']
    )
  )
  .on(
    event.aim.modify.creating.complete,
    withPhaseRequirements(
      (s, { payload: aimProps, selectedId, modifyPhase }) => {
        const draftAim = s.aims.get(selectedId)!

        if (!draftAim.parent) {
          throw Error('Parent should be always present for draftAim')
        }

        const parent = s.aims.get(draftAim.parent)!

        const updatedItems = new Map<I.Model.AimId, I.Model.Aim>([
          [parent.id, parent],
          [draftAim.id, { ...draftAim, ...aimProps }],
        ])

        if (modifyPhase === 'parentCreating') {
          const [childId] = draftAim.children

          // @TODO it should be draftAim?
          const childAim = s.aims.get(childId)!
          updatedItems.set(childAim.id, childAim)
        }

        effect.updateBoard([...updatedItems.values()])

        return {
          ...s,
          aims: s.aims.merge(updatedItems).clone(),
          controlPanel: { ...s.controlPanel, modifyPhase: null },
        }
      },
      ['childCreating', 'parentCreating']
    )
  )

/* removing */

$treeBoardStore
  .on(
    event.aim.modify.removing.start,
    withPhaseRequirements((s, { selectedId }) => {
      const isRoot = s.aims.get(selectedId)!.parent === undefined
      if (isRoot) return
      return { ...s, controlPanel: { ...s.controlPanel, modifyPhase: 'removing' } }
    })
  )
  .on(
    event.aim.modify.removing.cancel,
    withPhaseRequirements(
      (s) => ({ ...s, controlPanel: { ...s.controlPanel, modifyPhase: null } }),
      ['removing']
    )
  )
  .on(
    event.aim.modify.removing.complete,
    withPhaseRequirements(
      (_, { selectedId }) => {
        const timeTrackerState = timeTracker.$timeTrackerStore.getState()

        if (timeTrackerState.trackingAimId === selectedId) {
          timeTracker.event.tracking.stop()
        }

        event.aim.remove(selectedId)
      },
      ['removing']
    )
  )

/* Routing */

$treeBoardStore.on(
  event.aim.focus,
  withPhaseRequirements(
    (s) => {
      const selectedAimId = s.controlPanel.selectedAimId
      const rootAimId = s.controlPanel.rootAimId

      if (selectedAimId && selectedAimId !== rootAimId) {
        const url = `/branch/${selectedAimId}`
        window.changeRoute(url)
      }

      return s
    },
    [null]
  )
)

$treeBoardStore.on(
  event.aim.focusParentAim,
  withPhaseRequirements(
    (s) => {
      const rootAimId = s.controlPanel.rootAimId

      if (!rootAimId) return

      const parentId = s.aims.get(rootAimId)?.parent

      if (!parentId) return

      window.changeRoute(`/branch/${parentId}`)
    },
    [null]
  )
)

/* Features intersection */

$treeBoardStore.on(
  event.interface.selectTrackingAim,
  withPhaseRequirements(
    (s) => {
      const timeTrackerState = timeTracker.$timeTrackerStore.getState()
      const trackingAimId = timeTrackerState.trackingAimId
      const rootAimId = s.controlPanel.rootAimId

      if (!trackingAimId || !rootAimId) return s

      const rootAimDependencies = U.getDepSet(s.aims, rootAimId, 'children')

      if (!rootAimDependencies.get(trackingAimId)) {
        const auth = manageAuth.get()

        if (auth) {
          setTimeout(() => {
            window.changeRoute(`/branch/${auth.rootAimId}`)
          }, 0)
        }
      }

      return { ...s, controlPanel: { ...s.controlPanel, selectedAimId: trackingAimId } }
    },
    [null]
  )
)

function isFirstRootChild(aimId: I.Model.AimId): boolean {
  const state = $treeBoardStore.getState()
  const rootAimId = state.controlPanel.rootAimId

  if (!rootAimId) return false

  const rootAim = state.aims.get(rootAimId)

  if (!rootAim) {
    throw Error(`Could not find aim with id: ${rootAimId} in app state`)
  }

  const firstRootChildId = rootAim.children[0]

  return aimId === firstRootChildId
}

function copySelectedAimNameToClipboard() {
  const state = $treeBoardStore.getState()
  const selectedAimId = state.controlPanel.selectedAimId

  if (state.controlPanel.modifyPhase) return
  if (!selectedAimId) return

  navigator.clipboard.writeText(state.aims.get(selectedAimId)!.name)
}

$foldedAims.on(event.aim.foldToggle, (s) => {
  const treeBoard = $treeBoardStore.getState()

  const rootAimId = treeBoard.controlPanel.rootAimId
  const selectedAimId = treeBoard.controlPanel.selectedAimId

  const modifyingPhase = treeBoard.controlPanel.modifyPhase
  const notAllowedPhase = modifyingPhase && modifyingPhase != 'cutting'

  if (notAllowedPhase || !selectedAimId || !rootAimId) return

  const foldedOnRoot = s.get(rootAimId)

  if (foldedOnRoot) {
    foldedOnRoot.has(selectedAimId) //
      ? foldedOnRoot.delete(selectedAimId)
      : foldedOnRoot.add(selectedAimId)
  } else {
    s.set(rootAimId, new Set([selectedAimId]))
  }

  FoldedAimsLocalStorage.write(s)

  return s.clone()
})
