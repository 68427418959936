import React from 'react'
import { RouteComponentProps, Redirect } from 'react-router-dom'

import * as DOM from 'utils/DOM'
import * as feature from 'features'

import DataProvider from 'DataProvider'
import AimTree from 'components/AimTree/AimTree'
import Settings from 'components/Settings'
import AimDetails from 'components/AimDetails'
import Modal from 'components/Modal'

import TopPanel from './TopPanel'

import * as effect from './TreeBoardPage.effects'
import * as El from './TreeBoardPage.styles'

import * as I from 'types'

type Props = RouteComponentProps<{ branchId: I.Model.AimId }>

const MemoizedTreeBoardPageRenderer = React.memo(function TreeBoardPageRenderer(p: {
  rootAimId: I.RootAimId
}) {
  const { panel, detailsAimId } = effect.useTreeBoardKeyboard()

  return (
    <>
      {detailsAimId && (
        <Modal onOverlayClick={feature.crossStoreEvents.closeAimDetails}>
          <AimDetails id={detailsAimId} />
        </Modal>
      )}

      <El.Wrapper blurContent={!!detailsAimId}>
        <El.Board panel={panel}>
          <El.BoardHead>
            <TopPanel rootAimId={p.rootAimId} />
          </El.BoardHead>

          <El.BodyWrapper>
            <El.BoardBody id={DOM.getElId.treeBoardScrollEl}>
              <AimTree rootAimId={p.rootAimId} />
            </El.BoardBody>
          </El.BodyWrapper>
        </El.Board>

        {panel.settings && (
          <El.SettingsPanel>
            <Settings />
          </El.SettingsPanel>
        )}
      </El.Wrapper>
    </>
  )
})

const RenderTreeBoardPage = (p: { rootAimId: I.RootAimId }) => {
  const isRootAimIdValid = effect.useTreeBoard(p.rootAimId)

  if (isRootAimIdValid === false) {
    return <Redirect to="/404" />
  }

  return <MemoizedTreeBoardPageRenderer rootAimId={p.rootAimId} />
}

export default function TreeBoardPage(p: Props) {
  const rootAimId = p.match.params.branchId as I.RootAimId

  return <DataProvider element={<RenderTreeBoardPage rootAimId={rootAimId} />} />
}
