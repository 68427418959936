import { Key, WithKey, KeyboardEventSubscriber } from 'hooks/useKeyboard'

import * as feature from 'features'

import * as I from 'types'

export const getAimTreeKeymap = (): KeyboardEventSubscriber[] => {
  const select = (direction: I.Direction) => feature.treeBoard.event.aim.moveSelectionPointer(direction)
  const selectByTab = (selectionType: I.TabKeySelection) => {
    return (e: { preventDefault: any }) => {
      if (!feature.treeBoard.$treeBoardStore.getState().controlPanel.selectedAimId) {
        e.preventDefault()
        feature.treeBoard.event.aim.moveSelectionPointerByTabKey(selectionType)
      }
    }
  }

  const move = (direction: I.Direction) =>
    feature.treeBoard.event.aim.move({
      aimOnly: false,
      options: { type: 'order', direction: direction },
    })

  return [
    /* Routing */

    {
      description: 'Focus on particular aim (the currently focused node becomes root node)',
      key: [Key.f],
      type: 'keydown',
      on: () => feature.treeBoard.event.aim.focus(),
    },

    {
      description: 'Focus parent aim',
      key: [Key.b],
      type: 'keydown',
      with: [WithKey.alt],
      on: () => feature.treeBoard.event.aim.focusParentAim(),
    },

    /* Modify phase: editing */

    {
      description: 'Edit Aim',
      key: [Key.i],
      type: 'keydown',
      on: () => feature.treeBoard.event.aim.modify.editing.start(),
    },
    {
      description: 'Cancel edit Aim',
      key: [Key.escape],
      type: 'keydown',
      on: () => feature.treeBoard.event.aim.modify.editing.cancel(),
    },

    /* Modify phase: childCreating/parentCreating */

    {
      description: 'Create child Aim (child of selected Aim)',
      key: [Key.n, Key.enter],
      type: 'keydown',
      on: () => feature.treeBoard.event.aim.modify.creating.start({ creationType: 'childCreating' }),
    },
    {
      description: 'Create parent Aim (parent of selected Aim)',
      key: [Key.n, Key.enter],
      with: [WithKey.shift],
      type: 'keydown',
      on: () => feature.treeBoard.event.aim.modify.creating.start({ creationType: 'parentCreating' }),
    },

    /* Modify phase: removing */

    {
      description: 'Remove selected Aim',
      key: [Key.backspace],
      type: 'keydown',
      on: () => feature.treeBoard.event.aim.modify.removing.start(),
    },

    /* Cut & past */

    {
      description: 'Cut selected Aim',
      key: [Key.d],
      type: 'keydown',
      on: () => feature.treeBoard.event.aim.modify.cutting.start(),
    },
    {
      description: 'Cut selected Aim',
      key: [Key.x],
      with: [WithKey.ctrl, WithKey.cmd],
      type: 'keydown',
      on: () => feature.treeBoard.event.aim.modify.cutting.start(),
    },
    {
      description: 'Insert previously cut Aim',
      key: [Key.p],
      type: 'keydown',
      on: () => feature.treeBoard.event.aim.modify.cutting.complete(),
    },
    {
      description: 'Insert previously cut Aim',
      key: [Key.v],
      with: [WithKey.ctrl, WithKey.cmd],
      type: 'keydown',
      on: () => feature.treeBoard.event.aim.modify.cutting.complete(),
    },
    {
      description: 'Cancel cut Aim',
      key: [Key.escape],
      type: 'keydown',
      on: () => feature.treeBoard.event.aim.modify.cutting.cancel(),
    },

    /* Move selection pointer */

    {
      description: 'Select parent of the currently selected Aim',
      key: [Key.arrowUp, Key.k],
      type: 'keydown',
      on: () => select('up'),
    },
    {
      description: 'Select right sibling of the currently selected Aim',
      key: [Key.arrowRight, Key.l],
      type: 'keydown',
      on: () => select('right'),
    },
    {
      description: 'Select child of the currently selected Aim',
      key: [Key.arrowDown, Key.j],
      type: 'keydown',
      on: () => select('down'),
    },
    {
      description: 'Select left sibling of the currently selected Aim',
      key: [Key.arrowLeft, Key.h],
      type: 'keydown',
      on: () => select('left'),
    },
    {
      description: 'Select next Aim (TODO: need to be clarified)',
      key: [Key.tab],
      type: 'keydown',
      on: selectByTab('next'),
    },
    {
      description: 'Select previous Aim (TODO: need to be clarified)',
      key: [Key.tab],
      with: [WithKey.shift],
      type: 'keydown',
      on: selectByTab('prev'),
    },
    {
      description: 'Select tracking Aim',
      key: [Key.t],
      type: 'keydown',
      on: () => feature.treeBoard.event.interface.selectTrackingAim(),
    },

    /* Change item order */

    {
      description: 'Move selected Aim up',
      key: [Key.arrowUp, Key.k],
      with: [WithKey.shift],
      type: 'keydown',
      on: () => move('up'),
    },
    {
      description: 'Move selected Aim right',
      key: [Key.arrowRight, Key.l],
      with: [WithKey.shift],
      type: 'keydown',
      on: () => move('right'),
    },
    {
      description: 'Move selected Aim down',
      key: [Key.arrowDown, Key.j],
      with: [WithKey.shift],
      type: 'keydown',
      on: () => move('down'),
    },
    {
      description: 'Move selected Aim left',
      key: [Key.arrowLeft, Key.h],
      with: [WithKey.shift],
      type: 'keydown',
      on: () => move('left'),
    },

    /* Aim folding */

    {
      description: 'Move selected Aim left',
      key: [Key.h],
      with: [WithKey.alt],
      type: 'keydown',
      on: () => feature.treeBoard.event.aim.foldToggle(),
    },

    /* Extra selected Aim events */

    {
      description: 'Copy selected Aim name to clipboard',
      key: [Key.c],
      with: [WithKey.ctrl],
      type: 'keydown',
      on: () => feature.treeBoard.utils.copySelectedAimNameToClipboard(),
    },

    /* TimeTracker events */

    {
      description: 'Start or stop tracking time on the selected Aim',
      key: [Key.space],
      type: 'keydown',
      on: (e) => feature.crossStoreEvents.toggleTrackingTime(e),
    },

    /* Open different panels */

    {
      description: 'Toggle settings panel',
      key: [Key.p],
      type: 'keydown',
      with: [WithKey.alt],
      on: () => feature.page.event.toggleSettingsPanel(),
    },
    {
      description: 'Close settings panel',
      key: [Key.escape],
      type: 'keydown',
      on: close,
    },
    {
      description: 'Open selected Aim details',
      key: [Key.o],
      type: 'keydown',
      on: () => feature.crossStoreEvents.openAimDetails(),
    },
  ]
}

export function getSettingsPanelKeymap(): KeyboardEventSubscriber[] {
  return [
    {
      description: 'Toggle settings panel',
      key: [Key.p],
      type: 'keydown',
      with: [WithKey.alt],
      on: () => feature.page.event.toggleSettingsPanel(),
    },
  ]
}

export function getAimDetailsModalKeymap(): KeyboardEventSubscriber[] {
  return [
    {
      description: 'Hide settings panel',
      key: [Key.escape],
      type: 'keydown',
      on: () => feature.crossStoreEvents.closeAimDetails(),
    },
  ]
}
