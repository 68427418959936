import React, { useState, useRef, useEffect } from 'react'
import * as d3 from 'd3'

// Source https://observablehq.com/@d3/radial-dendrogram

type D3Node = {
  name: string
  children?: D3Node[]
}

type Props = {
  data: D3Node
}

function autoBox(this: any): any {
  document.body.appendChild(this)
  const { x, y, width, height } = this.getBBox()
  document.body.removeChild(this)
  return [x, y, width, height]
}

export default function RadialDendrogram(p: Props) {
  const [el, setEl] = useState<HTMLDivElement | null>(null)
  const svgRef = useRef<{ element?: SVGSVGElement }>({})

  useEffect(() => {
    if (el) {
      const width = 700
      const radius = width / 2

      const tree = d3.cluster().size([2 * Math.PI, radius - 100])
      const root = tree(d3.hierarchy(p.data) as any).sort((a: any, b: any) =>
        d3.ascending(a.data.name, b.data.name)
      )

      const svg = d3.create('svg')

      svg
        .append('g')
        .attr('fill', 'none')
        .attr('stroke', '#555')
        .attr('stroke-opacity', 0.4)
        .attr('stroke-width', 1)
        .selectAll('path')
        .data(root.links())
        .join('path')
        .attr(
          'd',
          (d3 as any)
            .linkRadial()
            .angle((d: any) => d.x)
            .radius((d: any) => d.y)
        )

      svg
        .append('g')
        .selectAll('circle')
        .data(root.descendants())
        .join('circle')
        .attr(
          'transform',
          (d) => `
        rotate(${(d.x * 180) / Math.PI - 90})
        translate(${d.y},0)
      `
        )
        .attr('fill', (d) => (d.children ? '#444' : '#666'))
        .attr('r', 1)

      svg
        .append('g')
        .attr('font-family', 'Roboto')
        .attr('font-size', 4)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-width', 0)
        .attr('stroke', 'white')
        .attr('fill', '#777')
        .selectAll('text')
        .data(root.descendants())
        .join('text')
        .attr(
          'transform',
          (d) => `
        rotate(${(d.x * 180) / Math.PI - 90}) 
        translate(${d.y},0) 
        rotate(${d.x >= Math.PI ? 180 : 0})
      `
        )
        .attr('dy', '0.31em')
        .attr('x', (d) => (d.x < Math.PI === !d.children ? 6 : -6))
        .attr('text-anchor', (d) => (d.x < Math.PI === !d.children ? 'start' : 'end'))
        .text((d: any) => d.data.name)
        .clone(true)
        .lower()

      const svgEl = svg.attr('viewBox', autoBox).node()

      if (svgEl) {
        if (!el.childNodes.length) {
          el.appendChild(svgEl)
          svgRef.current.element = svgEl
        } else {
          if (svgRef.current.element) {
            el.replaceChild(svgEl, svgRef.current.element)
            svgRef.current.element = svgEl
          }
        }
      }
    }
  })

  return <div ref={setEl}></div>
}
