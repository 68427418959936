import React from 'react'
import { useStore } from 'effector-react'

import { isNotNullable } from 'utils/checkers'
import * as time from 'utils/time'
import * as tree from 'utils/tree'
import * as feature from 'features'

import TimePeriod from 'components/TimePeriod'

import * as El from './AimDetails.styles'

import * as I from 'types'

export default function AimDetails(p: { id: I.Model.AimId }) {
  const { title, timePeriodsGroupedByDay } = useAimDetails(p.id)

  return (
    <El.Wrapper>
      <El.Title>{title}</El.Title>
      <El.TimePeriods>
        {timePeriodsGroupedByDay.map((day) => (
          <div key={day.dayTimestamp}>
            <El.Date>
              {time.format.date(day.dayTimestamp)} {time.format.fullDayOfWeek(day.dayTimestamp)}
              &nbsp;({time.formattedMS(day.timeSpent)})
            </El.Date>

            {day.items.map((x) => (
              <TimePeriod key={x.id} id={x.id} />
            ))}
          </div>
        ))}
      </El.TimePeriods>
    </El.Wrapper>
  )
}

function useAimDetails(id: I.Model.AimId) {
  const treeBoard = useStore(feature.treeBoard.$treeBoardStore)
  const timeTracker = useStore(feature.timeTracker.$timeTrackerStore)

  const relatedAims = tree.getDepSet(treeBoard.aims, id, 'children', { includeArchived: true })
  const relatedAimIds: I.Model.AimId[] = [...relatedAims.values()].map((aim) => aim.id)
  const timePeriodIds = relatedAimIds
    .map((id) => timeTracker.timePeriodIdsByLinkedEntityId.get(id))
    .filter(isNotNullable)
    .flat()

  const timePeriods = timePeriodIds.map((id) => timeTracker.timePeriodById.get(id)).filter(isNotNullable)
  const timePeriodsGroupedByDay = time.groupByDay(timePeriods)

  /// Newest first sort
  timePeriodsGroupedByDay.sort((a, b) => b.dayTimestamp - a.dayTimestamp)
  timePeriodsGroupedByDay.forEach((group) => group.items.sort((a, b) => b.from - a.from))

  const aim = treeBoard.aims.get(id)!

  return {
    title: aim.name,
    timePeriodsGroupedByDay,
  }
}
