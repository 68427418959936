import React from 'react'

import { formattedMS } from 'components/Timer'
import { useRootAimTimers } from './RootAimTimers.effects'

import * as El from './RootAimTimers.styles'

import * as I from 'types'

const TopPanelTimer = React.memo(function TopPanelTimer(p: { milliseconds: number; label: string }) {
  return (
    <El.TopPanelTimer>
      <El.TopPanelTimerLabel>{p.label}</El.TopPanelTimerLabel>
      <El.TopPanelTimerTime>{formattedMS(p.milliseconds)}</El.TopPanelTimerTime>
    </El.TopPanelTimer>
  )
})

const RootAimTimers = React.memo(function GenericTimers(p: { rootAimId: I.Model.AimId }) {
  const timer = useRootAimTimers(p.rootAimId)

  return (
    <El.Wrapper>
      <TopPanelTimer label="OVERALL" milliseconds={timer.overall} />
      <TopPanelTimer label="TODAY" milliseconds={timer.todaySpentTime} />

      {timer.session && <TopPanelTimer label="SESSION" milliseconds={timer.session} />}
    </El.Wrapper>
  )
})

export default RootAimTimers
