import { isEmpty } from 'utils/checkers'
import { BezierSetting } from 'components/Bezier'

import * as feature from 'features'

import RadialDendrogram from 'components/RadialDendrogram'

import * as I from 'types'
import { ITreeItem } from './AimTree'

const DENDROGRAM_NODE_NAME_MAX_LENGTH = 20
const MAX_TREE_DEPTH = 10000

export const mapStateToTree = (p: {
  aimPool: Map<I.Model.AimId, I.Model.Aim>
  selectAimIds: I.Model.AimId[]
  showAimsWithoutChildrenVerticalList: boolean
  maxDepth?: number
  depth?: number
  checkIsFolded: (aimId: I.Model.AimId) => boolean
}): ITreeItem[] => {
  if ((p.maxDepth || MAX_TREE_DEPTH) === p.depth || p.selectAimIds.length === 0) {
    return []
  }

  return p.selectAimIds
    .map((id) => p.aimPool.get(id))
    .filter((aim): aim is I.Model.Aim => !!aim && isEmpty(aim) === false && aim.isArchived !== false)
    .map((aim) => {
      const childrenHasNoChildren =
        p.showAimsWithoutChildrenVerticalList &&
        aim.children.filter(
          (aimId) => p.aimPool.get(aimId)!.children.length !== 0 && p.checkIsFolded(aimId) === false
        ).length === 0

      return {
        id: aim.id,
        name: aim.name,
        childrenHasNoChildren,
        children: aim.children,
        childrenItems: p.checkIsFolded(aim.id)
          ? []
          : mapStateToTree({ ...p, selectAimIds: aim.children, depth: (p.depth || 0) + 1 }),
      }
    })
}

export function mapItemsTreeToBezierSettings(
  aimPool: Map<I.Model.AimId, I.Model.Aim>,
  rootAimId: I.Model.AimId
): BezierSetting[] {
  const dependenciesSet = [...feature.treeBoard.utils.getDepSet(aimPool, rootAimId, 'children').values()]
  const rootAim = aimPool.get(rootAimId)!

  return dependenciesSet.reduce<BezierSetting[]>((acc, aim) => {
    const parentAimId = aim.id

    if (parentAimId === rootAimId) return acc

    aim.children.forEach((childAimId, idx) => {
      /// Root aim at top right corner has no bezier lines
      if (rootAim.children.includes(childAimId)) return

      /// First child of each Aim has no bezier line
      if (idx === 0) return

      acc.push({
        from: parentAimId,
        to: childAimId,
        positions: {
          start: { side: 'right' },
          end: { side: 'top' },
        },
      })
    })

    return acc
  }, [])
}

export function mapTreeItemToDendrogramData(
  treeItem: ITreeItem
): React.ComponentProps<typeof RadialDendrogram>['data'] {
  const name =
    treeItem.name.length >= DENDROGRAM_NODE_NAME_MAX_LENGTH
      ? treeItem.name.slice(0, DENDROGRAM_NODE_NAME_MAX_LENGTH) + '...'
      : treeItem.name

  return {
    name,
    children: treeItem.childrenItems
      .map(mapTreeItemToDendrogramData)
      .filter((x) => x.name !== feature.treeBoard.constants.DRAFT_AIM_NAME),
  }
}
