import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import { manageAuth } from 'local-storage'

import * as I from 'types'

export type RedirectState = {
  path: string
}

export type WithAuthInjectedProps = {
  auth: Extract<I.AppState.Auth, Record<string, unknown>>
}

/*
  The `withAuth` HOC and `src/pages/auth/LoginPage.tsx` components handles following effects:

  - If user trying to access route components requires authorization it redirects user to `/login` page
  - Hooks stores url before redirect and `LoginPage` component uses it as redirect path after successfull authentication
  - The wrapped by `withAuth` HOC components receiving aditional auth information in props
*/

export default function withAuth<BaseProps extends RouteComponentProps<any> & WithAuthInjectedProps>(
  BaseComponent: React.ComponentType<BaseProps>
) {
  class Hoc extends React.Component<BaseProps> {
    auth: I.AppState.Auth = null

    readUser = () => (this.auth = manageAuth.get())

    constructor(props: BaseProps) {
      super(props)
      this.readUser()
    }

    render() {
      this.readUser()

      if (!this.auth) {
        const state: RedirectState = {
          path: this.props.location.pathname + this.props.location.search,
        }

        return <Redirect to={{ state, pathname: '/login' }} />
      }

      return <BaseComponent {...this.props} auth={this.auth} />
    }
  }

  return Hoc
}
