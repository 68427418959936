const id = <T>(x: T) => x

export function animate(p: {
  draw: (progress: number) => void
  duration: number
  timing?: (timeFraction: number) => number /* Time function */
}) {
  const { timing = id, draw, duration } = p
  const start = performance.now()

  requestAnimationFrame(function animate(time) {
    let timeFraction = (time - start) / duration
    if (timeFraction > 1) timeFraction = 1

    const progress = timing(timeFraction)

    draw(progress)

    if (timeFraction < 1) {
      requestAnimationFrame(animate)
    }
  })
}
