import React from 'react'
import { Select as MaterialSelect, MenuItem } from '@material-ui/core'

import * as El from './Select.styles'

export default function Select<T>(p: {
  options: Array<{ value: T; label: string } | T>
  onChange: (optionValue: T) => void
  value: T
}) {
  const options = p.options.map((x: any) => (typeof x === 'string' ? { value: x, label: x } : x))

  return (
    <El.Wrapper>
      <MaterialSelect fullWidth value={p.value} onChange={(e) => p.onChange(e.target.value as any)}>
        {options.map(({ label, value }) => (
          <MenuItem value={value} key={value}>
            {label}
          </MenuItem>
        ))}
      </MaterialSelect>
    </El.Wrapper>
  )
}
