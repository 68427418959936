import React from 'react'
import './UnauthLayout.scss'

type Props = {
  children: React.ReactNode
  className?: string // inner div class name
}

export default function UnauthLayout(p: Props) {
  return (
    <div className="UnauthLayout">
      <div className={p.className}>{p.children}</div>
    </div>
  )
}
