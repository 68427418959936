import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 600px;
  max-width: 100%;
`

export const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 18px;
`

export const Timers = styled.div`
  margin-top: 14px;
`

export const TimePeriods = styled.div``

export const Date = styled.div`
  margin-top: 18px;
  margin-bottom: 16px;
  font-weight: bold;
`
