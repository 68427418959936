import React from 'react'

import { getAimTreeKeymap } from 'components/AimTree/AimTree.keymap'
import { WithKey } from 'hooks/useKeyboard'
import * as El from './HotkeysLegend.styles'

import { KeyboardEventSubscriber } from 'hooks/useKeyboard'

type Hotkey = {
  key: string
  description: string
}

const getHotkeyStrings = (p: Pick<KeyboardEventSubscriber, 'key' | 'with'>): string[] => {
  const keyOne = p.key.map((x) => (/^Key/.test(x) ? x.replace('Key', '').toLowerCase() : x)).join(' or ')

  if (!p.with) return [keyOne]

  const keyTwo = p.with
    .map((key) => {
      switch (key) {
        case WithKey.alt:
          return 'ALT'
        case WithKey.cmd:
          return 'CMD'
        case WithKey.ctrl:
          return 'CTRL'
        case WithKey.shift:
          return 'SHIFT'
      }
    })
    .join(' or ')

  return [keyOne, keyTwo]
}

const withBrackets = (x?: string) => (x ? `[ ${x} ]` : '')

const hotkeysLegend = getAimTreeKeymap().reduce<Hotkey[]>((acc, x) => {
  if (!x.description) return acc

  acc.push({
    key: getHotkeyStrings(x).map(withBrackets).join(' + '),
    description: x.description,
  })

  return acc
}, [])

const HotkeysLegend = () => (
  <El.HotkeysLegend>
    {hotkeysLegend.map((p, index) => (
      <El.Hotkey key={index}>
        <El.HotkeyKey>{p.key}</El.HotkeyKey>
        <El.HotkeyDescription>{p.description}</El.HotkeyDescription>
      </El.Hotkey>
    ))}
  </El.HotkeysLegend>
)

export default React.memo(HotkeysLegend)
