import styled from 'styled-components'

export const HotkeysLegend = styled.div``

export const Hotkey = styled.div`
  margin: 18px 0;
`

export const HotkeyKey = styled.div``
export const HotkeyDescription = styled.div`
  margin-top: 8px;
`
