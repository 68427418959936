import React from 'react'

import UnauthLayout from 'components/UnauthLayout'

export default function NotFoundPage() {
  return (
    <UnauthLayout>
      <div>404 Not Found</div>
    </UnauthLayout>
  )
}
