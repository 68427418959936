import { useStore } from 'effector-react'
import { useDrag } from 'react-dnd'

import useSelector from 'hooks/useSelector'
import useTheme from 'hooks/useTheme'
import useSettings from 'hooks/useSettings'

import * as feature from 'features'

import * as utils from './AimTree.utils'

import * as I from 'types'
import { ITreeItem } from './AimTree'

export const useAimTree = (rootAimId: I.RootAimId) => {
  const aimPool = useStore(feature.treeBoard.$aimById)
  const foldedAims = useStore(feature.treeBoard.$foldedAims)
  const settings = useSettings()

  return {
    treeItems: utils.mapStateToTree({
      aimPool,
      selectAimIds: [rootAimId],
      checkIsFolded: (aimId) => foldedAims.get(rootAimId)?.has(aimId) === true,
      showAimsWithoutChildrenVerticalList: settings.treeBoard.behaviour.showAimsWithoutChildrenVerticalList,
    }),
    bezierSettings: utils.mapItemsTreeToBezierSettings(aimPool, rootAimId),
  }
}

function useAimBranchDnD(id: I.Model.AimId) {
  const [{ isDragging }, dragRef] = useDrag({
    item: { id, type: 'aim' } as I.DnDItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  return {
    isDragging,
    dragRef,
  }
}

export function useIsFolded(id: I.Model.AimId, rootAimId: I.RootAimId) {
  const foldedAims = useStore(feature.treeBoard.$foldedAims)
  return foldedAims.get(rootAimId)?.has(id) || false
}

export function useAimBranch(p: {
  rootAimId: I.RootAimId
  branchId: I.Model.AimId
  childrenHasNoChildren: boolean
}) {
  const dragNDrop = useAimBranchDnD(p.branchId)
  const isCutting = useSelector(feature.treeBoard.$controlPanel, (s) => s.cutAimId === p.branchId)
  const isFolded = useIsFolded(p.branchId, p.rootAimId)
  const settings = useSettings()

  const isRootBranch = p.rootAimId === p.branchId
  const renderChildrenAsVerticalList =
    p.childrenHasNoChildren &&
    isRootBranch === false &&
    settings.treeBoard.behaviour.showAimsWithoutChildrenVerticalList

  return { dragNDrop, isRootBranch, renderChildrenAsVerticalList, isCutting, isFolded }
}

export function useDendrogramData(rootItem: ITreeItem) {
  const theme = useTheme()

  const radialDendrogramData = (() => {
    if (theme.treeBoard.background.dendrogram.enabled === false) return

    const data = utils.mapTreeItemToDendrogramData(rootItem)

    if (feature.treeBoard.utils.findNodeBy(data, (node) => !!(node.children && node.children.length > 1))) {
      return data
    }
  })()

  return radialDendrogramData
}
