import React from 'react'
import { DragElementWrapper } from 'react-dnd'

import * as DOM from 'utils/DOM'
import AimForm from 'components/AimForm/AimForm'
import ConfirmDenyDialog from 'components/ConfirmDenyDialog'
import Timer, { useTimeTracker } from 'components/Timer'

import * as El from './AimItem.styles'
import * as I from 'types'

type Props = {
  id: I.Model.AimId
  name: string

  alwaysShowTimer: boolean

  isTracking: boolean
  isActive: boolean
  isSelected: boolean
  isRoot: boolean
  isFirstRootChild: boolean /* TODO used for scroll into view logic we need to get rid of this property */
  isFolded: boolean
  hasChildren: boolean

  modifyPhase: I.ModifyPhase

  dropRef: DragElementWrapper<HTMLDivElement>
  scrollIntoViewElRef: React.MutableRefObject<HTMLDivElement | null>

  onOpenAimDetails: () => void
  onSelectAim: () => void
  onEditingComplete: (newName: string) => void
  onRemovingConfirmed: () => void
  onRemovingDenied: () => void
  onCreatingComplete: (name: string) => void
  onCreatingCancelled: () => void
}

const SelectedAim = (p: {
  modifyPhase: I.ModifyPhase
  name: string

  onEditingComplete: (newName: string) => void
  onRemovingConfirmed: () => void
  onRemovingDenied: () => void
  onCreatingComplete: (name: string) => void
  onCreatingCancelled: () => void
}) => {
  switch (p.modifyPhase) {
    case 'parentCreating':
    case 'childCreating': {
      return (
        <AimForm
          initialValues={{}}
          onSubmit={(values) => p.onCreatingComplete(values.name)}
          onCancel={p.onCreatingCancelled}
        />
      )
    }

    case 'editing':
      return (
        <AimForm initialValues={{ name: p.name }} onSubmit={(values) => p.onEditingComplete(values.name)} />
      )

    case 'removing':
      return (
        <ConfirmDenyDialog open={true} onConfirm={p.onRemovingConfirmed} onDeny={p.onRemovingDenied}>
          Are you sure you want to archive this aim and all dependencies?
        </ConfirmDenyDialog>
      )

    default:
      return null
  }
}

const AimItemTimer = (p: { id: I.Model.AimId; alwaysShowTimer: boolean }) => {
  const timeTracker = useTimeTracker(p.id)

  if (p.alwaysShowTimer === false && timeTracker.spentTimeMilliseconds === 0) {
    return null
  }

  return (
    <El.Timer>
      <Timer
        isTracking={timeTracker.isTracking}
        spentTimeMilliseconds={timeTracker.spentTimeMilliseconds}
        onTrackingStart={timeTracker.handle.tracking.start}
        onTrackingStop={timeTracker.handle.tracking.stop}
      />
    </El.Timer>
  )
}

const SHOW_STATIC_NAME_ALLOWED_PHASES: I.ModifyPhase[] = [null, 'cutting', 'removing']

export default React.memo(function AimItem(p: Props) {
  const showStaticName = !p.isSelected || SHOW_STATIC_NAME_ALLOWED_PHASES.includes(p.modifyPhase)
  const isEditing = p.modifyPhase === 'editing' && p.isSelected

  return (
    <El.Wrapper onClick={p.onSelectAim} onDoubleClick={p.onOpenAimDetails}>
      <El.Body
        id={DOM.getElId.aimItem(p.id)}
        ref={p.dropRef}
        isEditing={isEditing}
        isRoot={p.isRoot}
        isSelected={p.isSelected}
      >
        <AimItemTimer id={p.id} alwaysShowTimer={p.alwaysShowTimer} />

        {showStaticName && p.name}
        {showStaticName && p.isFolded && <El.Folded>{p.hasChildren ? '....' : '...'}</El.Folded>}

        {p.isSelected && p.modifyPhase && (
          <SelectedAim
            name={p.name}
            modifyPhase={p.modifyPhase}
            onEditingComplete={p.onEditingComplete}
            onRemovingConfirmed={p.onRemovingConfirmed}
            onRemovingDenied={p.onRemovingDenied}
            onCreatingComplete={p.onCreatingComplete}
            onCreatingCancelled={p.onCreatingCancelled}
          />
        )}
      </El.Body>
    </El.Wrapper>
  )
})
