import styled from 'styled-components'

export const BranchChildrenContainer = styled.div<{ renderChildrenAsVerticalList: boolean }>`
  display: flex;
  padding: 0px;
  flex-direction: ${(p) => (p.renderChildrenAsVerticalList ? 'column' : 'row')};
`

export const Dendrogram = styled.div`
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  filter: blur(4px);
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  z-index: 0;
  animation: spin 2000s linear infinite;
`

export const AimBranch = styled.div<{ withPadding: boolean; semiTransparent: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 0px;
  padding: ${(p) => (p.withPadding ? '4px 4px 0px 4px' : '0px')};
  opacity: ${(p) => `${p.semiTransparent ? '0.5' : '1'}`};
`

export const DraggingOverlay = styled.div`
  border-radius: 4px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  z-index: 10;
  opacity: 0.4;
`
