import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import RootProviders from 'RootProviders'
import { rootEl } from '../../index'

import * as El from './Modal.styles'

type Props = {
  children: JSX.Element | JSX.Element[]
  onOverlayClick?: () => void
}

export function useModal(
  /**
   * WARNING! If a `Modal` component is nested within another `Modal`
   * component, and both are initially open, they may not render in
   * the anticipated order due to portal behaviour in JavaScript.
   * Specifically, child components to parent components.
   * Consequently, the inner `Modal` might be invisible to the
   * user.
   **/
  isOpenInitially = false
): {
  isOpen: boolean
  toggleOpen: () => void
  show: () => void
  hide: () => void
} {
  const [isOpen, setIsOpen] = useState(() => isOpenInitially)
  const toggleOpen = () => setIsOpen((x) => !x)
  const show = () => setIsOpen(true)
  const hide = () => setIsOpen(false)

  return { isOpen, show, hide, toggleOpen }
}

const stopPropagation: React.MouseEventHandler<HTMLElement> = (e) => e.stopPropagation()

export default function Modal(p: Props) {
  /**
   * We use ReactDOM.createPortal in the Modal component primarily
   * because the Modal overlay element is fixed positioned. Without
   * a portal, if a parent element with "display: flex" is mounted,
   * the Modal would be relative to the parent flex element, not the
   * viewport. Using createPortal, we make sure the Modal
   * positions against the viewport, disregarding the parent's CSS
   * properties.
   */

  return ReactDOM.createPortal(
    <RootProviders>
      <El.Overlay onClick={p.onOverlayClick}>
        <El.Body onClick={stopPropagation}>{p.children}</El.Body>
      </El.Overlay>
    </RootProviders>,
    rootEl
  )
}
