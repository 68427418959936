export function fromArrToMapByKey<T extends object, K extends keyof T>(list: T[], key: K): Map<T[K], T> {
  const result = new Map<T[K], T>()
  list.forEach((x) => result.set(x[key], x))

  return result
}

export function mapOmitBy<T, K>(target: Map<K, T>, by: (x: T) => boolean): Map<K, T> {
  const result = new Map<K, T>()

  target.forEach((value, key) => {
    if (by(value) == false) {
      result.set(key, value)
    }
  })

  return target
}
