import React from 'react'
import { format } from 'date-fns'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { useStore } from 'effector-react'

import { formattedMS } from 'utils/time'
import * as feature from 'features'

import ConfirmDenyDialog, { useConfirmDenyDialog } from 'components/ConfirmDenyDialog'
import Modal, { useModal } from 'components/Modal'
import DateTimePicker from 'components/DateTimePicker'

import * as El from './TimePeriod.styles'
import * as I from 'types'

export default function TimePeriod(p: { id: I.Model.TimePeriodId }) {
  const { name, from, to, spentTime, timePeriod } = useTimePeriod(p.id)

  const confirmRemoving = useConfirmDenyDialog()
  const editModal = useModal()

  const handleTimePeriodChangeSubmit = (updatedValues: {
    from: I.Model.Timestamp
    to: I.Model.Timestamp
  }): void => {
    feature.timeTracker.event.timePeriod.update({ ...timePeriod, ...updatedValues })
    editModal.hide()
  }

  return (
    <El.Wrapper>
      {editModal.isOpen && (
        <Modal onOverlayClick={editModal.hide}>
          <EditTimePeriod initialValues={timePeriod} onSubmit={handleTimePeriodChangeSubmit} />
        </Modal>
      )}

      {
        <ConfirmDenyDialog
          {...confirmRemoving}
          onConfirm={() => {
            feature.timeTracker.event.timePeriod.remove(p.id)
            confirmRemoving.set.open(false)
          }}
          onDeny={() => confirmRemoving.set.open(false)}
        >
          <div>Are you sure you want to delete time period?</div>
        </ConfirmDenyDialog>
      }

      <El.DateTime>
        {from} - {to} : {spentTime}
      </El.DateTime>

      <El.Name>{name}</El.Name>

      <El.RemoveBtn onClick={() => confirmRemoving.set.open(true)}>
        <DeleteIcon />
      </El.RemoveBtn>

      <El.EditBtn onClick={editModal.show}>
        <EditIcon />
      </El.EditBtn>
    </El.Wrapper>
  )
}

function useTimePeriod(id: I.Model.TimePeriodId) {
  const treeBoard = useStore(feature.treeBoard.$treeBoardStore)
  const timeTracker = useStore(feature.timeTracker.$timeTrackerStore)

  const timePeriod = timeTracker.timePeriodById.get(id)

  if (!timePeriod) throw Error('Could not find required TimePeriod')

  const aim = treeBoard.aims.get(timePeriod.linkedEntityId)!

  const from = timePeriod.from
  const to = timePeriod.to || Date.now()
  const spentTime = to - from
  const formatter = (timestamp: number) => format(timestamp, 'HH:mm')

  return {
    name: aim.name,
    spentTime: formattedMS(spentTime),
    from: formatter(from),
    to: formatter(to),
    timePeriod,
  }
}

function EditTimePeriod(p: {
  initialValues: {
    from?: I.Model.Timestamp
    to?: I.Model.Timestamp
  }

  onSubmit: (updatedValues: { from: I.Model.Timestamp; to: I.Model.Timestamp }) => void
}) {
  const [error, setError] = React.useState<string | undefined>()
  const [fromTimestamp, setFromTimestamp] = React.useState<I.Model.Timestamp | undefined>(
    p.initialValues.from
  )
  const [toTimestamp, setToTimestamp] = React.useState<I.Model.Timestamp | undefined>(p.initialValues.to)

  const handleFromChange = (value: I.Model.Timestamp | undefined) => {
    setError(undefined)
    setFromTimestamp(value)
  }

  const handleToChange = (value: I.Model.Timestamp | undefined) => {
    setError(undefined)
    setToTimestamp(value)
  }

  const handleSubmit = (e?: { preventDefault?: () => void }) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    if (fromTimestamp === undefined || toTimestamp === undefined) {
      setError('Both fields must be set before submit')
      return
    }

    p.onSubmit({ from: fromTimestamp, to: toTimestamp })
  }

  return (
    <form onSubmit={handleSubmit}>
      {error && <div>{error}</div>}

      <div>
        <div>FROM:</div>
        <DateTimePicker value={fromTimestamp} onChange={handleFromChange} />
      </div>

      <div>
        <div>TO:</div>
        <DateTimePicker value={toTimestamp} onChange={handleToChange} />
      </div>

      <button type="submit">Submit</button>
    </form>
  )
}
