import * as I from 'types'

const AUTH_LOCAL_STORAGE_KEY = 'authUser'

const setObject = <T extends { [k: string]: string | number | null }>(key: string, data: T | null) =>
  localStorage.setItem(key, JSON.stringify(data))

const getObject = <T>(name: string): T | null => {
  try {
    const data = localStorage.getItem(name)
    const parsedData = data ? JSON.parse(data) : null
    return parsedData
  } catch (e) {
    console.error(e)
    return null
  }
}

export const manageAuth = {
  get: (): I.AppState.Auth => {
    const auth = getObject<I.AppState.Auth>(AUTH_LOCAL_STORAGE_KEY)

    if (typeof auth !== 'object') return null
    if (auth === null) return null

    if (typeof auth.id !== 'string') return null
    if (typeof auth.token !== 'string') return null
    if (typeof auth.username !== 'string') return null
    if (typeof auth.rootAimId !== 'string') return null

    return { id: auth.id, token: auth.token, username: auth.username, rootAimId: auth.rootAimId }
  },
  set: (p: I.AppState.Auth) => setObject(AUTH_LOCAL_STORAGE_KEY, p),
}

export abstract class FoldedAimsLocalStorage {
  static storageItemKey = 'FOLDED_AIMS_LOCAL_STORAGE_KEY'

  static read(): Map<I.RootAimId, Set<I.Model.AimId>> {
    const result = new Map<I.RootAimId, Set<I.Model.AimId>>()
    const storage = window.localStorage.getItem(FoldedAimsLocalStorage.storageItemKey)

    if (!storage) return result

    try {
      const obj = JSON.parse(storage) as { [rootAimId: string]: Array<string /* aimId */> }

      Object.keys(obj).forEach((key) => {
        const value = obj[key]

        if (Array.isArray(value) === false) return

        const filteredValue = value.filter((x): x is I.Model.AimId => {
          return typeof x === 'string'
        })

        if (filteredValue.length) {
          result.set(key as I.RootAimId, new Set([...filteredValue]))
        }
      })
    } catch (_) {
      console.warn(`Failed to parse foldedAims local storage obj, the new one will be created`)
    }

    return result
  }

  static write(foldedAims: Map<I.RootAimId, Set<I.Model.AimId>>) {
    const objToStringify: { [rootAimId: string]: Array<string /* aimId */> } = {}
    foldedAims.forEach((aimIds, rootAimId) => (objToStringify[rootAimId] = [...aimIds]))

    window.localStorage.setItem(FoldedAimsLocalStorage.storageItemKey, JSON.stringify(objToStringify))
  }
}
