import React from 'react'
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { SxProps } from '@mui/system'

import * as I from 'types'

type Timestamp = I.Model.Timestamp

type Props = {
  value: Timestamp | undefined
  sx?: SxProps
  onChange: (value: Timestamp | undefined) => void
}

export default function DateTimePicker(p: Props) {
  const onChange = (x: Date | null) => {
    if (x instanceof Date) {
      const timestamp = x.getTime() as Timestamp

      if (isNaN(timestamp)) {
        p.onChange(undefined)
        return
      }

      p.onChange(timestamp)
      return
    }

    p.onChange(undefined)
  }

  const date = p.value === undefined ? null : new Date(p.value)

  return <MuiDateTimePicker sx={p.sx} value={date} onChange={onChange} />
}
