import qs from 'qs'
import { omit } from '../utils/record'

import * as I from 'types'

const SEARCH_PARAMS_VALIDATION: { [name in keyof Required<I.SearchParams>]: I.SearchParamsValidators[] } = {
  detailsAimId: [{ type: 'string' }],
}

const GENERIC_ENCODE_DECODE_PAIRS: Array<any> = [
  ['&', '||amp||'],
  ['#', '||hsh||'],
  ['+', '||pls||'],
  ['%', '||prt||'],
]

export function replaceURL(url: string | number) {
  window.history.replaceState(null, '', `${url}`)
}

function makeSpecialSymbolsManager(pairs: Array<any>) {
  return {
    encode: (str: string) => pairs.reduce((s, [symbol, code]) => s.replaceAll(symbol, code), str),
    decode: (str: string) => pairs.reduce((s, [symbol, code]) => s.replaceAll(code, symbol), str),
  }
}

const genericSymbols = makeSpecialSymbolsManager(GENERIC_ENCODE_DECODE_PAIRS)

function decodeQueryParams(obj: any) {
  const result: any = {}
  for (const key in obj) {
    const value = obj[key]
    result[key] = genericSymbols.decode(value)
  }

  return result
}

export function parseRawSearchParams(
  search: string,
  paramPrefix?: string
): {
  /* "rest" is all params with exception of prefixed, if `paramPrefix` is not present should be empty object */
  restRawSearchParams: { [paramKey: string]: string }
  scopedRawSearchParams: { [paramKey: string]: string }
} {
  const searchParamsString = search.slice(1, search.length)
  const searchParams = decodeQueryParams(qs.parse(searchParamsString, { parseArrays: false }))

  const filteredParamsByPrefix = ((): { [paramName: string]: string } => {
    if (!paramPrefix) {
      return searchParams
    }

    const result: typeof searchParams = {}

    Object.keys(searchParams).forEach((prefixedKey) => {
      if (prefixedKey.slice(0, paramPrefix.length) !== paramPrefix) {
        return
      }

      const value = searchParams[prefixedKey]
      const localKey = prefixedKey.slice(paramPrefix.length, prefixedKey.length)

      result[localKey] = value
    })

    return result
  })()

  return {
    restRawSearchParams: omit(searchParams, Object.keys(filteredParamsByPrefix)),
    scopedRawSearchParams: filteredParamsByPrefix,
  }
}

const SEARCH_PARAMS_VALIDATION_TUPLES = Object.entries(SEARCH_PARAMS_VALIDATION) as Array<
  [keyof I.SearchParams, I.SearchParamsValidators[]]
>

export function parseSearchParams(search: string, paramPrefix?: string) {
  const { scopedRawSearchParams, restRawSearchParams } = parseRawSearchParams(search, paramPrefix)

  return {
    restSearchParams: restRawSearchParams,
    scopedSearchParams: normalizeSearchParams(scopedRawSearchParams),
  }
}

export function normalizeSearchParams(historySearchParams: I.SearchParams): I.SearchParams {
  const result: any = {} // "any" type instead of "SearchParams" to simplify normalization process

  SEARCH_PARAMS_VALIDATION_TUPLES.forEach(([parameterName, validators]) => {
    const value = historySearchParams[parameterName]

    if (value === null || value === undefined) {
      return
    }

    validators.forEach((validator) => {
      switch (validator.type) {
        case 'string': {
          if (typeof value !== 'string') {
            return
          }

          if (!validator.allowEmpty && value.length === 0) {
            return
          }

          if (validator.shouldMatch && !value.match(validator.shouldMatch)) {
            return
          }

          result[parameterName] = value
          break
        }
        case 'boolean': {
          result[parameterName] = value === 'true' ? true : false
          break
        }
        case 'number': {
          const parsedValue = typeof value === 'string' ? parseFloat(value) : value

          if (typeof parsedValue !== 'number' || isNaN(parsedValue)) {
            return
          }

          if (validator.min && parsedValue < validator.min) {
            return
          }

          if (validator.max && parsedValue > validator.max) {
            return
          }

          result[parameterName] = parsedValue
          break
        }
        case 'filter': {
          if (typeof value !== 'string') {
            return
          }

          const items = parseFilterQuery(value)

          if (items) {
            result[parameterName] = items
          }

          break
        }
        default: {
          const { type } = validator
          return new UnreachableCaseError(type)
        }
      }
    })
  })

  return result
}

export function encodeSearchParams(historySearchParams: I.SearchParams): { [param: string]: string } {
  const result: any = {} // "any" type instead of "SearchParams" to simplify encoding process

  SEARCH_PARAMS_VALIDATION_TUPLES.forEach(([parameterName, validators]) => {
    const value = historySearchParams[parameterName]

    if (value === null) {
      return (result[parameterName] = null)
    }

    if (value === undefined) {
      return
    }

    validators.forEach((validator) => {
      switch (validator.type) {
        case 'string': {
          if (!validator.allowEmpty && value === '') {
            return
          }

          result[parameterName] = value
          break
        }
        case 'boolean':
        case 'number': {
          result[parameterName] = `${value}`
          break
        }
        case 'filter': {
          result[parameterName] = encodeFilterQuery(value as any) || undefined
          break
        }
        default: {
          const { type } = validator
          return new UnreachableCaseError(type)
        }
      }
    })
  })

  return result
}

export function searchParamEncode(value: string): string {
  return value.replace(/\s|\W/g, '_').replace(/_+/g, '_').toLowerCase() as any
}

export function addPrefixToObjectKeys(obj: any, prefix: string): any {
  const result: any = {}
  for (const key in obj) {
    result[prefix + key] = obj[key]
  }

  return result
}

export function clearEmptyProps(obj: any) {
  const result: any = {}
  for (const key in obj) {
    const value = obj[key]

    if (value !== null && value !== undefined) {
      result[key] = value
    }
  }

  return result
}

export function encodeQueryParams(obj: any) {
  const result: any = {}
  for (const key in obj) {
    const value = obj[key]

    if (typeof value === 'string') {
      result[key] = genericSymbols.encode(value)
    } else {
      result[key] = value
    }
  }

  return result
}

const filterQuerySymbols = makeSpecialSymbolsManager([
  ['[', '||lbr||'],
  [']', '||rbr||'],
  ['"', '||dqt||'],
  [',', '||cma||'],
])

export function parseFilterQuery(query: string): Array<string[]> | undefined {
  const getItems = (q: string, items: string[] = []): string[] => {
    const bracketIndex = q.indexOf(']')

    if (bracketIndex < 0) {
      return items
    }

    const head = q.slice(0, bracketIndex + 1)
    const tail = q.slice(bracketIndex + 2, q.length)

    items.push(head)

    return getItems(tail, items)
  }

  const items = getItems(query)
    .filter((x) => x[0] === '[' && x[x.length - 1] === ']')
    .map((x) =>
      x
        .replace(/\[|\]|"/g, '')
        .split(',')
        .map(filterQuerySymbols.decode)
    )
    .filter((x) => x.length)

  return items.length ? items : undefined
}

export function encodeFilterQuery(items: Array<string[]>) {
  return items.map((x) => `[${x.map((y) => `"${filterQuerySymbols.encode(y)}"`).join(',')}]`).join(',')
}

export class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${JSON.stringify(val)}`)
  }
}

export function parseLocationQP(paramPrefix?: string): I.SearchParams {
  const { search } = window.location
  const { restRawSearchParams, scopedRawSearchParams } = parseRawSearchParams(search, paramPrefix)

  return { ...restRawSearchParams, ...normalizeSearchParams(scopedRawSearchParams) }
}

export function getUpdatedPathname(params: I.SearchParams, paramPrefix?: string): string {
  /* In order to allow multi level memoization is better to read `window.location` directly */
  const { search, pathname } = window.location
  const { restRawSearchParams, scopedRawSearchParams } = parseRawSearchParams(search, paramPrefix)
  const scopedSearchParams = normalizeSearchParams(scopedRawSearchParams)

  const updatedParams = encodeSearchParams({ ...scopedSearchParams, ...params })
  const prefixedParams = paramPrefix ? addPrefixToObjectKeys(updatedParams, paramPrefix) : updatedParams

  /* If prefix not present we need to preserve existed params from another context */
  const restRawScopedParams = paramPrefix ? {} : scopedRawSearchParams

  const resultParams = { ...restRawSearchParams, ...restRawScopedParams, ...prefixedParams }
  const searchStr = '?' + qs.stringify(encodeQueryParams(clearEmptyProps(resultParams)), { encode: false })

  if (searchStr.length === 1) return pathname

  return `${pathname}${searchStr}`
}

export function patchLocationQP(params: I.SearchParams, paramPrefix?: string) {
  replaceURL(getUpdatedPathname(params, paramPrefix))
}

export default {
  parse: parseLocationQP,
  patch: patchLocationQP,
}
