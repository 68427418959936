import React from 'react'
import { Store } from 'effector'

export default function useSelector<State, SelectorReturn>(
  store: Store<State>,
  selector: (state: State) => SelectorReturn,
  selectorHash?: string
): SelectorReturn {
  const [state, setState] = React.useState(() => selector(store.getState()))

  React.useEffect(() => {
    const watcher = store.watch((s: State) => setState(selector(s)))
    return () => watcher.unsubscribe()
  }, [selectorHash])

  return state
}
