import styled from 'styled-components'

export const TopPanel = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 8px;

  color: rgb(222, 200, 200);

  > div + div {
    margin-left: 12px;
  }
`

export const RootAim = styled.div``
export const Timers = styled.div``
export const RightCornerBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  padding-right: 32px;
`

export const Logout = styled.div`
  font-size: 12px;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`
export const Help = styled(Logout)`
  margin-left: 24px;
`
