import React from 'react'
import ReactDOM from 'react-dom'
import extendNativeJsMethods from 'js'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { enGB } from 'date-fns/locale'

import './index.css'
import * as serviceWorker from './serviceWorker'

import Router from './Router'
import packageJSON from '../package.json'

import EitherVimiumExtensionWarning from 'components/EitherVimiumExtensionWarning'
import RootProviders from './RootProviders'

extendNativeJsMethods()

export const rootEl = (() => {
  const htmlEl = document.getElementById('root')
  if (!htmlEl) throw Error('Can not mount react app because "root" element is not found')
  return htmlEl
})()

// Log app environment & version
;(function () {
  const message = `version: ${packageJSON.version} alpha; NODE_ENV: ${process.env.NODE_ENV}`
  console.log(`%c${message}`, 'color: purple; font-weight: 900; font-size: 14px')
})()

ReactDOM.render(
  <RootProviders>
    <EitherVimiumExtensionWarning>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <Router />
      </LocalizationProvider>
    </EitherVimiumExtensionWarning>
  </RootProviders>,
  rootEl
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
