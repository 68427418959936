import React from 'react'

import * as DOM from 'utils/DOM'

import RadialDendrogram from 'components/RadialDendrogram'
import Bezier from 'components/Bezier'
import AimItem, { useAimItem } from 'components/AimItem'

import * as effects from './AimTree.effects'
import * as El from './AimTree.styles'

import * as I from 'types'

export type ITreeItem = Pick<I.Model.Aim, 'id' | 'children' | 'name'> & {
  childrenItems: ITreeItem[]
  childrenHasNoChildren: boolean
}

const AimItemWithEffects = React.memo(function AimItemWithEffects(p: {
  id: I.Model.AimId
  rootAimId: I.RootAimId
  name: string
}) {
  const e = useAimItem(p.id, p.rootAimId)

  return (
    <AimItem
      id={p.id}
      name={p.name}
      alwaysShowTimer={false}
      isTracking={e.isTracking}
      isActive={e.isActive}
      isSelected={e.isSelected}
      isRoot={e.isRoot}
      isFirstRootChild={e.isFirstRootChild}
      isFolded={e.isFolded}
      hasChildren={e.hasChildren}
      dropRef={e.dropRef}
      scrollIntoViewElRef={e.scrollIntoViewElRef}
      modifyPhase={e.modifyPhase}
      onOpenAimDetails={e.onOpenAimDetails}
      onSelectAim={e.onSelectAim}
      onEditingComplete={e.onEditingComplete}
      onRemovingConfirmed={e.onRemovingConfirmed}
      onRemovingDenied={e.onRemovingDenied}
      onCreatingComplete={e.onCreatingComplete}
      onCreatingCancelled={e.onCreatingCancelled}
    />
  )
})

const AimBranch = (p: { treeItem: ITreeItem; rootId: I.RootAimId }) => {
  const { isRootBranch, dragNDrop, isCutting, isFolded, renderChildrenAsVerticalList } = effects.useAimBranch(
    {
      rootAimId: p.rootId,
      branchId: p.treeItem.id,
      childrenHasNoChildren: p.treeItem.childrenHasNoChildren,
    }
  )

  return (
    <El.AimBranch
      id={DOM.getElId.aimBranch(p.treeItem.id)}
      data-root={isRootBranch}
      withPadding={isRootBranch === false}
      semiTransparent={isCutting}
    >
      {dragNDrop.isDragging && <El.DraggingOverlay />}

      {/* Focused Aim is rendered at `src/routes/TreeBoardPage/TopPanel/TopPanel.tsx` */}
      {!isRootBranch && (
        <div ref={dragNDrop.dragRef}>
          <AimItemWithEffects id={p.treeItem.id} rootAimId={p.rootId} name={p.treeItem.name} />
        </div>
      )}

      {!isFolded && (
        <El.BranchChildrenContainer renderChildrenAsVerticalList={renderChildrenAsVerticalList}>
          {p.treeItem.childrenItems.map((child) => (
            <AimBranch key={child.id} treeItem={child} rootId={p.rootId} />
          ))}
        </El.BranchChildrenContainer>
      )}
    </El.AimBranch>
  )
}

const AimTreeRender = React.memo(function AimTreeRender(p: { treeItems: ITreeItem[] }) {
  const [rootItem] = p.treeItems
  const radialDendrogramData = effects.useDendrogramData(rootItem)

  return (
    <El.BranchChildrenContainer renderChildrenAsVerticalList={true}>
      {radialDendrogramData && (
        <El.Dendrogram>
          <RadialDendrogram data={radialDendrogramData} />
        </El.Dendrogram>
      )}

      {p.treeItems.map((treeItem) => (
        <AimBranch key={treeItem.id} treeItem={treeItem} rootId={treeItem.id as I.RootAimId} />
      ))}
    </El.BranchChildrenContainer>
  )
})

export default function AimTree(p: { rootAimId: I.RootAimId }) {
  const { treeItems, bezierSettings } = effects.useAimTree(p.rootAimId)

  return (
    <Bezier
      forbidToShowFromLeftToRightLines
      settings={bezierSettings}
      treeScrollElId={DOM.getElId.treeBoardScrollEl}
    >
      <AimTreeRender treeItems={treeItems} />
    </Bezier>
  )
}
