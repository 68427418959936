// Types declaration described at `src/window.d.ts`
export type InferMapKey<T> = T extends Map<infer K, any> ? K : never
export type InferMapValue<T> = T extends Map<any, infer V> ? V : never

export default function extendNativeJsMethods() {
  ///////////
  /// Map ///
  ///////////

  /* Constructor methods */

  Map.fromArray = function (arr, key) {
    const x = new Map()
    arr.forEach((value) => x.set(value[key], value))
    return x
  }

  /* Mutable methods */

  Map.prototype.merge = function (mergeWith) {
    mergeWith.forEach((value, key) => this.set(key, value))
    return this
  }

  Map.prototype.omit = function (keys) {
    keys.forEach((k) => this.delete(k))
    return this
  }

  Map.prototype.deleteBy = function (by) {
    this.forEach((value, key) => {
      if (by(value, key)) this.delete(key)
    })
    return this
  }

  /* Immutable methods */

  Map.prototype.clone = function () {
    const x = new Map()
    this.forEach((value, key) => x.set(key, value))
    return x
  }

  Map.prototype.pick = function (keys) {
    const x = new Map()
    keys.forEach((k) => {
      const candidate = this.get(k)
      if (candidate) x.set(k, candidate)
    })

    return x
  }

  Map.prototype.valuesBy = function (by) {
    const x: Array<any> = []

    this.forEach((value, key) => {
      if (by(value, key)) x.push(value)
    })

    return x.length > 0 ? x : null
  }
}
