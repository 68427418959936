import { useMemo, useRef, useEffect } from 'react'

import * as feature from 'features'

import * as time from 'utils/time'
import * as tree from 'utils/tree'

import { useSharedIntervalForcedRender } from 'hooks/useForceRender'
import { formattedMS } from 'components/Timer'

import * as I from 'types'

const MAX_AIM_IN_TITLE_LENGTH = 18

function clampString(value: string, maxLength: number): string {
  if (value.length < maxLength) return value
  return value.slice(0, maxLength) + '...'
}

type Timers = {
  trackingAimId: null | I.Model.AimId
  lastUpdated: number
  todaySpentTime: number
  overall: number
  session: null | number
}

export const useRootAimTimers = (rootAimId: I.Model.AimId): Timers => {
  const forceRender = useSharedIntervalForcedRender(true)

  const timeTracker = feature.timeTracker.$timeTrackerStore.getState()
  const treeBoard = feature.treeBoard.$treeBoardStore.getState()

  const timePeriodById = timeTracker.timePeriodById
  const aimById = treeBoard.aims

  const orderedTimePeriods = useMemo(
    () => getOrderedTimerPeriods({ timePeriodById, aimById, rootAimId }),
    [timePeriodById, aimById, rootAimId]
  )

  const lastUpdated = Date.now()
  const trackingAimId = timeTracker.trackingAimId

  const timersRef = useRef<Timers>(calculateTimers({ orderedTimePeriods, lastUpdated, trackingAimId }))

  useEffect(() => {
    const updatedTimers = calculateTimers({ orderedTimePeriods, lastUpdated, trackingAimId })
    timersRef.current = updatedTimers
    forceRender()
  }, [orderedTimePeriods])

  if (trackingAimId) {
    if (trackingAimId === timersRef.current.trackingAimId) {
      const timeDiff = lastUpdated - timersRef.current.lastUpdated

      timersRef.current = {
        lastUpdated,
        trackingAimId,
        todaySpentTime: timersRef.current.todaySpentTime + timeDiff,
        overall: timersRef.current.overall + timeDiff,
        session: (timersRef.current.session || 0) + timeDiff,
      }
    } else {
      timersRef.current = { ...calculateTimers({ orderedTimePeriods, lastUpdated, trackingAimId }) }
    }
  } else {
    timersRef.current.trackingAimId = null
    timersRef.current.session = null
  }

  updatePageTitle({
    aimById,
    rootAimId,
    trackingAimId,
    spentTimeContinuously: timersRef.current.session,
  })

  return timersRef.current
}

function getOrderedTimerPeriods(p: {
  timePeriodById: I.AppState.TimeTracker['timePeriodById']
  aimById: I.AppState.TreeBoard['aims']
  rootAimId: I.Model.AimId
}): I.Model.TimePeriod[] {
  const rootAimDependentAims = tree.getDepSet(p.aimById, p.rootAimId, 'children', {
    includeArchived: true,
  })

  return [...p.timePeriodById.values()]
    .filter((timePeriod) => !!rootAimDependentAims.get(timePeriod.linkedEntityId))
    .sort((a, b) => b.from - a.from)
}

function calculateTimers(p: {
  orderedTimePeriods: I.Model.TimePeriod[]
  lastUpdated: number
  trackingAimId: I.Model.AimId | null
}): Timers {
  return {
    trackingAimId: p.trackingAimId,
    lastUpdated: p.lastUpdated,
    todaySpentTime: time.getTodaySpentTime(p.orderedTimePeriods),
    session: time.getSpentTimeContinuouslyFromNow(p.orderedTimePeriods) || null,
    overall: time.getOverallSpentTime(p.orderedTimePeriods),
  }
}

function updatePageTitle(p: {
  aimById: I.AppState.TreeBoard['aims']
  rootAimId: I.Model.AimId
  trackingAimId: I.Model.AimId | null
  spentTimeContinuously: number | null
}): void {
  const rootAimShortenedName = clampString(p.aimById.get(p.rootAimId)!.name, MAX_AIM_IN_TITLE_LENGTH + 12)

  if (p.trackingAimId && p.spentTimeContinuously) {
    const formattedSpentTime = formattedMS(p.spentTimeContinuously)
    const trackingAim = p.aimById.get(p.trackingAimId)!
    const shortenedTrackingAimName = clampString(trackingAim.name, MAX_AIM_IN_TITLE_LENGTH)

    document.title = shortenedTrackingAimName + ' ' + formattedSpentTime
  } else if (rootAimShortenedName !== document.title) {
    document.title = rootAimShortenedName
  }
}
