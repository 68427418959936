import React from 'react'
import { useStore } from 'effector-react'
import { DefaultTheme } from 'styled-components'
import { Switch, Button } from '@material-ui/core'

import useTheme from 'hooks/useTheme'
import {
  resetSelectedProfileToDefault,
  updateCurrentProfile,
  selectProfile,
  resetSettings,
  deleteProfile,
  $settings,
} from 'features/settings'

import { useModal } from 'components/Modal'

import ColorInput from 'components/ColorInput'
import Select from 'components/Select'

import AddNewProfileModal from './AddNewProfileModal'

import * as El from './Settings.styles'

const FONT_FAMILIES: DefaultTheme['general']['font']['family'][] = ['Roboto', 'Diablo']

function SettingsBlock(p: { title: string; children: JSX.Element | JSX.Element[] }) {
  return (
    <El.SettingsBlock>
      <El.SettingsBlockHead>{p.title}</El.SettingsBlockHead>
      <El.SettingsBlockBody>{p.children}</El.SettingsBlockBody>
    </El.SettingsBlock>
  )
}

function ResetAllSettingsToDefault() {
  return (
    <Button
      variant="contained"
      onClick={() => resetSettings()}
      style={{ marginTop: '12px', marginBottom: '12px' }}
    >
      Reset all settings to default
    </Button>
  )
}

function SwitchSetting(p: { name: string; onChange: (checked: boolean) => void; checked: boolean }) {
  return (
    <El.SwitchSetting>
      <Switch onChange={(e) => p.onChange(e.target.checked)} checked={p.checked} />
      <El.SwitchSettingName>{p.name}</El.SwitchSettingName>
    </El.SwitchSetting>
  )
}

function ColorSetting(p: { name: string; onChange: (color: string) => void; color: string }) {
  return (
    <El.ColorSetting>
      <El.ColorSettingName>{p.name}</El.ColorSettingName>
      <ColorInput color={p.color} onChange={p.onChange} />
    </El.ColorSetting>
  )
}

function SelectSetting<T>(p: {
  name: string
  options: Array<{ value: T; label: string } | T>
  onChange: (optionValue: T) => void
  value: T
}) {
  return (
    <El.SelectSetting>
      <El.SelectSettingName>{p.name}</El.SelectSettingName>
      <Select options={p.options} value={p.value} onChange={p.onChange} />
    </El.SelectSetting>
  )
}

function GeneralSettings() {
  const settings = useTheme()

  return (
    <SettingsBlock title="General">
      <SwitchSetting
        name="Background dendrogram"
        onChange={(checked) =>
          updateCurrentProfile({ treeBoard: { background: { dendrogram: { enabled: checked } } } })
        }
        checked={settings.treeBoard.background.dendrogram.enabled}
      />

      <SwitchSetting
        name="Use previously selected children on branch when (moving selection pointer `up` and subsequent `down` using keyboard)"
        onChange={(checked) =>
          updateCurrentProfile({ treeBoard: { behaviour: { usePreviousSelectedChildren: checked } } })
        }
        checked={settings.treeBoard.behaviour.usePreviousSelectedChildren}
      />

      <SwitchSetting
        name="Show branch children that has not children as vertical list instead of horizontal"
        onChange={(checked) =>
          updateCurrentProfile({ treeBoard: { behaviour: { showAimsWithoutChildrenVerticalList: checked } } })
        }
        checked={settings.treeBoard.behaviour.showAimsWithoutChildrenVerticalList}
      />

      <ColorSetting
        name="Background color"
        color={settings.treeBoard.background.color}
        onChange={(color) => updateCurrentProfile({ treeBoard: { background: { color } } })}
      />

      <SelectSetting
        name="Primary Font family"
        options={FONT_FAMILIES}
        value={settings.general.font.family}
        onChange={(value) => updateCurrentProfile({ general: { font: { family: value } } })}
      />
    </SettingsBlock>
  )
}

function SettingProfiles() {
  const settings = useStore($settings)
  const addNewProfileModal = useModal()

  const selectedProfile = settings.profiles[settings.selectedProfile]
  const availableProfiles = Object.keys(settings.profiles)

  return (
    <El.SettingProfiles>
      <SelectSetting
        name="Selected settings profile"
        options={availableProfiles}
        value={settings.selectedProfile}
        onChange={selectProfile}
      />
      <El.SettingProfilesControls>
        <Button
          variant="contained"
          disabled={!selectedProfile.isFactoryPresent}
          onClick={() => resetSelectedProfileToDefault()}
        >
          Reset
        </Button>

        <Button variant="contained" onClick={addNewProfileModal.show}>
          Add
        </Button>

        {addNewProfileModal.isOpen && (
          <AddNewProfileModal
            onClose={addNewProfileModal.hide}
            profileOptions={availableProfiles}
            selectedProfileId={settings.selectedProfile}
          />
        )}

        <Button
          variant="contained"
          disabled={selectedProfile.isFactoryPresent}
          onClick={() => deleteProfile()}
        >
          Delete
        </Button>
      </El.SettingProfilesControls>
    </El.SettingProfiles>
  )
}

export default function Settings() {
  return (
    <El.Wrapper>
      <El.Title>SETTINGS</El.Title>
      <ResetAllSettingsToDefault />
      <SettingProfiles />
      <GeneralSettings />
    </El.Wrapper>
  )
}
