import React from 'react'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import './EitherVimiumExtensionWarning.scss'

import hasVimium from 'utils/detect-vimium'

type Props = { children: React.ReactNode }

const IS_EXCEPTION_CODE_FEATURE_ENABLED = false
const VIMIUM_DOMAIN_EXCEPTION_CODE = 'dfhijklmnptvx'
const VIMIUM_EXTENSION_WARNING_WAS_SHOWN = 'vimium_extension_warning_was_shown'

function getShouldShowWarning(): boolean {
  if (hasVimium() === false) return false

  return window.localStorage.getItem(VIMIUM_EXTENSION_WARNING_WAS_SHOWN) !== 'true'
}

function Warning(p: { confirm: () => void }) {
  React.useEffect(() => {
    window.localStorage.setItem(VIMIUM_EXTENSION_WARNING_WAS_SHOWN, 'true')
  }, [])

  const [isCodeCopied, setIsCodeCopied] = React.useState(false)

  return (
    <div className="EitherVimiumExtensionWarning">
      <form
        onSubmit={(e) => {
          e.preventDefault()
          p.confirm()
        }}
      >
        <div>
          We detected Vimium extension in your browser. We are using similar key bindings so we recommend to
          disable this extension on our domain. We will not show this message again on current browser (unless
          incognito mode).
          <br />
          <br />
          Go to{' '}
          <a href="https://github.com/philc/vimium/wiki/Disabling-Vimium">vimium wiki Disabling Vimium</a> for
          details.
          {IS_EXCEPTION_CODE_FEATURE_ENABLED && (
            <div>
              <div>Required exceptions:</div>
              <div className="EitherVimiumExtensionWarning__code">
                <FileCopyIcon
                  className={`EitherVimiumExtensionWarning__button ${isCodeCopied ? 'copied' : ''}`}
                  onClick={() => {
                    navigator.clipboard.writeText(VIMIUM_DOMAIN_EXCEPTION_CODE).then(() => {
                      setIsCodeCopied(true)
                    })
                  }}
                />
                <div className="EitherVimiumExtensionWarning__body">
                  <div>{VIMIUM_DOMAIN_EXCEPTION_CODE}</div>
                </div>
              </div>
              <div>Copy and past it to our domain exception</div>
            </div>
          )}
        </div>
        <div>
          <input autoFocus style={{ opacity: 0, width: 0, height: 0 }} />
        </div>
        <button type="submit">Alright</button>
      </form>
    </div>
  )
}

export default function EitherVimiumExtensionWarning(p: Props) {
  const [shouldShowWarning, setShouldShowWarning] = React.useState(getShouldShowWarning())

  if (shouldShowWarning) {
    return <Warning confirm={() => setShouldShowWarning(false)} />
  }

  return <>{p.children}</>
}
