import { useReducer, useMemo, useRef, useState } from 'react'
import { debounce } from '../utils/time'

import { isEmpty } from 'utils/checkers'

const ASYNC_VALIDATION_DEBOUNCE_TIMEOUT = 1000

/*
 * Form methods call flow:
 *
 * -> (componentDidMount -> assign initialValues to state values) || onChange |FormField|
 *
 * -> normalize
 * -> validate
 *
 * (if valid branch) {
 *    -> asyncValidate
 *    -> onChange |FormFieldStruct|
 * }
 *
 * -> represent
 * -> structInterceptor
 *
 * @TODO the typings of this module is too complicated and optional "extension" flow could be simplified
 */

type FieldBaseProps<ValueType> = {
  _uid: string
  initialValue?: ValueType
  label?: string

  inputType?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  readOnly?: boolean

  normalize?: (value: ValueType, ctx: FormContext, _uid: string) => ValueType // modify value right after change
  represent?: (value: ValueType, ctx: FormContext, _uid: string) => ValueType // modify value right before render

  validate?: (value: ValueType, ctx: FormContext, _uid: string) => string | undefined
  asyncValidate?: (value: ValueType, ctx: FormContext, _uid: string) => Promise<string | undefined>
  onChange?: (value: ValueType, ctx: FormContext, _uid: string) => void

  /* Use for async field update make sure function returns correct promise */
  invokeNetworkUpdate?: (value: ValueType, ctx: FormContext, _uid: string) => Promise<any | void>

  /* Use for modifying result field props depends on field state and form context within the field props */
  structInterceptor?: (f: FormFieldStruct<ValueType>) => FormFieldStruct<ValueType>

  onBlur?: (f: FormFieldStruct<ValueType>) => void
  onFocus?: (f: FormFieldStruct<ValueType>) => void
}

type FormFieldGeneric<ValueType, FieldExtension = null> = FieldExtension extends null
  ? FieldBaseProps<ValueType>
  : FieldBaseProps<ValueType> & { extension?: FieldExtension }

/*
 * Types used as layout structure ("useDynamicForm" return type)
 */

type Option<T = any> = { label: string; value: T }

// make sure value types are same as for FormFieldStruct
export type FormField<FieldExtension> =
  | ({ type: 'string' } & FormFieldGeneric<string, FieldExtension>)
  | ({ type: 'number' } & FormFieldGeneric<number, FieldExtension>)
  | ({ type: 'options'; options: Option[] } & FormFieldGeneric<any, FieldExtension>)
  | ({ type: 'any' } & FormFieldGeneric<any>)

type FieldGroupBaseProps<GroupExtension, FieldExtension> = {
  fields: FormField<FieldExtension>[]
  children?: Array<
    GroupExtension extends null
      ? FieldGroupBaseProps<GroupExtension, FieldExtension>[]
      : FieldGroupBaseProps<GroupExtension, FieldExtension> & {
          extension?: GroupExtension
        }
  >
}

export type FieldGroup<GroupExtension = null, FieldExtension = null> = GroupExtension extends null
  ? FieldGroupBaseProps<null, FieldExtension>
  : FieldGroupBaseProps<GroupExtension, FieldExtension> & {
      extension?: GroupExtension
    }

/*
 * Types used as "fieldGroups" layout structure ("useDynamicForm" return type)
 */

type OnChange<ValueType> = { onChange: (value: ValueType) => void }
type FormFieldBaseStruct<FieldExtension, ValueType> = Omit<
  FormFieldGeneric<ValueType, FieldExtension>,
  'onChange' | 'normalize' | 'validate' | 'asyncValidate' | 'structInterceptor' | 'invokeNetworkUpdate'
> &
  OnChange<ValueType> & {
    error?: string
    ctx: FormContext
    invokeNetworkUpdate: (value: any) => void
  }

// make sure value types are same as for FormField
export type FormFieldStruct<FieldExtension> =
  | ({ type: 'string'; value: string } & FormFieldBaseStruct<FieldExtension, string>)
  | ({ type: 'number'; value: number } & FormFieldBaseStruct<FieldExtension, number>)
  | ({ type: 'options'; options: Option[]; value: any } & FormFieldBaseStruct<FieldExtension, any>)
  | ({ type: 'any'; value: any } & FormFieldBaseStruct<FieldExtension, any>)

type FieldGroupBaseStructProps<GroupExtension, FieldExtension> = {
  fields: FormFieldStruct<FieldExtension>[]
  children?: Array<
    GroupExtension extends null
      ? FieldGroupBaseStructProps<GroupExtension, FieldExtension>[]
      : FieldGroupBaseStructProps<GroupExtension, FieldExtension> & {
          extension?: GroupExtension
        }
  >
}

export type FieldGroupStruct<GroupExtension = null, FieldExtension = null> = GroupExtension extends null
  ? FieldGroupBaseStructProps<GroupExtension, FieldExtension>
  : FieldGroupBaseStructProps<GroupExtension, FieldExtension> & {
      extension?: GroupExtension
    }

/*
 * Form state and context types
 */

type PendingRequest = {
  _uid: string
  timestamp: number
}

type DynamicFormState = Readonly<{
  pendingRequests: Readonly<PendingRequest[]>
  values: Readonly<{ [_uid in string]?: any }>
  errors: Readonly<{ [_uid in string]?: string }>
}>

type StateControls = {
  pendingRequests: {
    start: (x: PendingRequest) => void
    end: (x: PendingRequest) => void
  }
  errors: { merge: (x: Record<string, string | undefined>) => void }
  values: { merge: (x: Record<string, any>) => void }
  changeValue: (x: { _uid: string; value: any; error?: string }) => void
}

export type FormContext = DynamicFormState & {
  fields: { [_uid: string]: FormField<any> }
  controls: StateControls
  initialValues: { [_uid in string]?: any }
}

const ACTIONS = {
  pendingRequests: {
    start: (req: PendingRequest) => ({ type: 'START_PENDING_REQ' as 'START_PENDING_REQ', req }),
    end: (req: PendingRequest) => ({ type: 'END_PENDING_REQ' as 'END_PENDING_REQ', req }),
  },
  values: {
    merge: (values: DynamicFormState['values']) => ({ type: 'MERGE_VALUES' as 'MERGE_VALUES', values }),
  },
  errors: {
    merge: (errors: DynamicFormState['errors']) => ({ type: 'MERGE_ERRORS' as 'MERGE_ERRORS', errors }),
  },
  changeValue: (payload: { _uid: string; value: any; error?: string }) => ({
    type: 'CHANGE_VALUE' as 'CHANGE_VALUE',
    payload,
  }),
}

type SubmittingError = { isError: boolean; message?: string }

export type FormSchema<GroupExtension = null, FieldExtension = null> = Array<
  FieldGroup<GroupExtension, FieldExtension>
>

export type FormConfig<GroupExtension = null, FieldExtension = null> = {
  handleSubmit?: (values: any, ctx: FormContext) => Promise<any>
  schema: FieldGroup<GroupExtension, FieldExtension>[]
}

const NO_SUBMITTING_ERROR: SubmittingError = { isError: false }
const SUBMITTING_REQ_KEY = '||submitting||'

const useDynamicForm = <GE, FE>(
  config: FormConfig<GE, FE>
): {
  fieldGroups: Array<FieldGroupStruct<GE, FE>>
  handleSubmit: React.FormEventHandler<HTMLFormElement>
  submit: () => void
  hasPendingRequests: boolean
  isValid: boolean
  isSubmitting: boolean
  ctx: FormContext
  submittingError: SubmittingError
} => {
  const fieldIdsSet = useMemo(
    (): Set<string> => new Set(config.schema.map((x) => x.fields.map((x) => x._uid)).flat()),
    [config.schema]
  )

  const [submittingError, setSubmittingError] = useState<SubmittingError>(NO_SUBMITTING_ERROR)
  const asyncValidatorsDebouncerRefs = useRef<{ [_uid in string]?: { cancel: () => void } }>({})
  const initialValues = useMemo(() => collectInitialValues(config.schema), [config.schema])
  const fields = useMemo(() => collectFields(config.schema), [config.schema])

  const [state, dispatch] = useReducer(dynamicFormReducer, {
    values: initialValues,
    errors: {},
    pendingRequests: [],
  })

  const controls: StateControls = {
    pendingRequests: {
      start: (req) => dispatch(ACTIONS.pendingRequests.start(req)),
      end: (req) => dispatch(ACTIONS.pendingRequests.end(req)),
    },
    values: { merge: (x) => dispatch(ACTIONS.values.merge(x)) },
    errors: { merge: (x) => dispatch(ACTIONS.errors.merge(x)) },
    changeValue: (x) => dispatch(ACTIONS.changeValue(x)),
  }

  const ctx: FormContext = { ...state, controls, initialValues, fields }

  const fieldGroups: Array<FieldGroupStruct<GE, FE>> = mapFieldsWith(config.schema, (f: FormField<FE>) => {
    const value = state.values[f._uid]
    const error = state.errors[f._uid]

    const validateInterceptor = validateInterceptorFactory(asyncValidatorsDebouncerRefs, ctx, f)

    const onChange = validateInterceptor({
      before: (v) => {
        setSubmittingError(NO_SUBMITTING_ERROR)
        controls.changeValue({ _uid: f._uid, value: v })
      },
      success: (v) => {
        if (f.onChange) {
          f.onChange(v, ctx, f._uid)
        }
      },
    })

    const invokeNetworkUpdate = f.invokeNetworkUpdate
      ? validateInterceptor({
          success: (v) => {
            if (f.invokeNetworkUpdate) {
              const pendingRequest = { _uid: f._uid, timestamp: Date.now() }
              ctx.controls.pendingRequests.start(pendingRequest)

              f.invokeNetworkUpdate(v, ctx, f._uid)
                .catch(() => ctx.controls.errors.merge({ [f._uid]: 'Network error' }))
                .finally(() => ctx.controls.pendingRequests.end(pendingRequest))
            }
          },
        })
      : () => undefined

    const { represent } = f

    const struct: any = {
      ...f,
      ctx,
      error,
      onChange,
      invokeNetworkUpdate,
      value: !isEmpty(value) && represent ? represent(value as never, ctx, f._uid) : value,
    }

    return f.structInterceptor ? f.structInterceptor(struct) : struct
  })

  const hasPendingRequests = state.pendingRequests.length > 0

  const isValid =
    (matchFieldsBy(fieldGroups, (f) => f.required === true) || [])
      .map(({ _uid, type }) => {
        const hasNoError = !state.errors[_uid]
        const _value = state.values[_uid]

        const value = typeof _value === 'string' ? _value.trim() : _value
        const notEmpty = type === 'number' ? typeof value === 'number' : !!value
        const noPendingValidations = !asyncValidatorsDebouncerRefs.current[_uid]

        return hasNoError && notEmpty && noPendingValidations
      })
      .includes(false) === false

  const submit = () => {
    if (isValid && config.handleSubmit) {
      const pendingRequest = { _uid: SUBMITTING_REQ_KEY, timestamp: Date.now() }
      controls.pendingRequests.start(pendingRequest)
      config
        .handleSubmit(state.values, ctx)
        .catch((error) => {
          if (error instanceof Error) {
            setSubmittingError({ isError: true, message: error.message || 'Unknown submitting error' })
            return
          }

          if (error instanceof Object) {
            const probableFieldIds = Object.keys(error)
            const errorMessage = `The "handleSubmit" callback expected throw of object where keys is part of _uid schema and values are error messages`

            for (const fieldId of probableFieldIds) {
              if (fieldIdsSet.has(fieldId) === false) {
                throw Error(errorMessage)
              }

              if (typeof error[fieldId] !== 'string') {
                throw Error(errorMessage)
              }
            }

            controls.errors.merge(error)
            setSubmittingError({ isError: true })
            return
          }

          if (typeof error === 'string') {
            setSubmittingError({ isError: true, message: error })
            return
          }

          throw Error('Unsupported type of error has been thrown in "handleSubmit" callback')
        })
        .finally(() => {
          controls.pendingRequests.end(pendingRequest)
        })
    }
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    submit()
  }

  const isSubmitting = !!state.pendingRequests.find((x) => x._uid === SUBMITTING_REQ_KEY)

  return {
    fieldGroups,
    isValid,
    handleSubmit,
    submit,
    ctx,
    hasPendingRequests,
    isSubmitting,
    submittingError,
  }
}

function validateInterceptorFactory(
  asyncValidatorsDebouncerRefs: React.MutableRefObject<Record<string, { cancel: () => void } | undefined>>,
  ctx: FormContext,
  f: FormField<any>
) {
  return (callback: {
    before?: (normalizedValue: any) => void
    success?: (normalizedValue: any) => void
    error?: (error: string) => void
  }) => {
    return (value: any) => {
      const { controls } = ctx

      if (ctx.errors[f._uid]) {
        controls.errors.merge({ [f._uid]: undefined })
      }

      const normalizedValue = f.normalize ? f.normalize(value as never, ctx, f._uid) : value

      if (callback.before) {
        callback.before(normalizedValue)
      }

      const debouncer = asyncValidatorsDebouncerRefs.current[f._uid]

      if (debouncer) {
        debouncer.cancel()
        asyncValidatorsDebouncerRefs.current[f._uid] = undefined
      }

      const error = f.validate && f.validate(normalizedValue as never, ctx, f._uid)

      if (error) {
        controls.errors.merge({ [f._uid]: error })
        if (callback.error) {
          callback.error(error)
        }
      }

      const { asyncValidate } = f

      if (!asyncValidate) {
        if (callback.success) {
          callback.success(normalizedValue)
        }
        return
      }

      asyncValidatorsDebouncerRefs.current[f._uid] = debounce(() => {
        const pendingRequest: PendingRequest = {
          _uid: f._uid,
          timestamp: Date.now(),
        }
        controls.pendingRequests.start(pendingRequest)

        asyncValidate(normalizedValue as never, ctx, f._uid)
          .then((error) => {
            asyncValidatorsDebouncerRefs.current[f._uid] = undefined
            controls.errors.merge({ [f._uid]: error as string })

            if (callback.success && !error) {
              callback.success(normalizedValue)
            }

            if (callback.error && error) {
              callback.error(normalizedValue)
            }
          })
          .finally(() => controls.pendingRequests.end(pendingRequest))
      }, ASYNC_VALIDATION_DEBOUNCE_TIMEOUT)

      const debounced: any = asyncValidatorsDebouncerRefs.current[f._uid]
      debounced()
    }
  }
}

export function mapFieldsWith<T extends { fields: any[] }, R = null>(
  fieldGroups: T[],
  mapper: (field: T['fields'][0]) => T['fields'][0]
): R extends null ? T[] : R {
  return fieldGroups.map((group: any) => {
    return {
      ...group,
      fields: group.fields.map(mapper),
      children: group.children && mapFieldsWith(group.children, mapper),
    }
  }) as any
}

function collectInitialValues<GE, FE>(fieldGroups: FieldGroup<GE, FE>[]): { [_uid: string]: any } {
  const result: { [_uid: string]: any } = {}
  mapFieldsWith(fieldGroups, (field) => (result[field._uid] = field.initialValue))
  return result
}

function collectFields<GE, FE>(fieldGroups: FieldGroup<GE, FE>[]): { [_uid: string]: FormField<any> } {
  const result: { [_uid: string]: any } = {}
  mapFieldsWith(fieldGroups, (field) => (result[field._uid] = field))
  return result
}

function matchFieldsBy<GE, FE>(
  fieldGroups: Array<FieldGroupStruct<GE, FE>>,
  condition: (field: FormFieldStruct<FE>) => boolean
): undefined | Array<FormFieldStruct<FE>> {
  const result: Array<FormFieldStruct<FE>> = []
  mapFieldsWith(fieldGroups, (field): any => {
    if (condition(field)) {
      result.push(field)
    }
  })
  return result.length ? result : undefined
}

type Actions =
  | ReturnType<typeof ACTIONS.pendingRequests.start>
  | ReturnType<typeof ACTIONS.pendingRequests.end>
  | ReturnType<typeof ACTIONS.values.merge>
  | ReturnType<typeof ACTIONS.changeValue>
  | ReturnType<typeof ACTIONS.errors.merge>

function dynamicFormReducer(state: DynamicFormState, action: Actions): DynamicFormState {
  switch (action.type) {
    case 'MERGE_VALUES': {
      return { ...state, values: { ...state.values, ...action.values } }
    }
    case 'MERGE_ERRORS': {
      return { ...state, errors: { ...state.errors, ...action.errors } }
    }
    case 'CHANGE_VALUE': {
      const { _uid, value, error } = action.payload
      return {
        ...state,
        values: { ...state.values, [_uid]: value },
        errors: { ...state.errors, [_uid]: error },
      }
    }
    case 'START_PENDING_REQ': {
      const { req } = action
      return { ...state, pendingRequests: [...state.pendingRequests, req] }
    }
    case 'END_PENDING_REQ': {
      const { req } = action
      return {
        ...state,
        pendingRequests: state.pendingRequests.filter((x) => x !== req),
      }
    }

    default: {
      return state
    }
  }
}

export function recursiveFormRenderer<GE, FE>(
  fieldGroups: FieldGroupStruct<GE, FE>[],
  renderer: (
    group: Omit<FieldGroupStruct<GE, FE>, 'children'>,
    idx: number,
    children?: React.ReactNode
  ) => React.ReactNode
): React.ReactNode {
  return fieldGroups.map((g, idx) =>
    renderer(g, idx, g.children && recursiveFormRenderer(g.children as any, renderer))
  )
}

export default useDynamicForm
