import styled from 'styled-components'
import { ThemedScroll } from 'sc'

import * as I from 'types'

export const Wrapper = styled.div<{ blurContent: boolean }>`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: ${(p) => p.theme.treeBoard.background.color};
  font-family: ${(p) => p.theme.general.font.family};
  /* transition: 200ms filter linear; */
  /* filter: ${(x) => (x.blurContent ? 'blur(3px) brightness(0.7)' : 'blur(0px)')}; */
`

export const Board = styled.div<{ panel: I.Panel }>`
  width: ${(p) => {
    const reducedWidth = p.panel.settings ? p.theme.treeBoard.settingsPanel.width : 0
    return `calc(100vw - ${reducedWidth}px)`
  }};
  height: 100vh;
  overflow: hidden;
`

export const BoardHead = styled.div`
  height: ${(p) => p.theme.treeBoard.head.height}px;
`

export const BodyWrapper = styled.div`
  height: ${(p) => `calc(100vh - ${p.theme.treeBoard.head.height}px)`};
  padding-left: 4px;
`

export const BoardBody = styled(ThemedScroll)`
  height: 100%;
  overflow: auto;
`

export const SettingsPanel = styled.div`
  width: ${(p) => p.theme.treeBoard.settingsPanel.width}px;
`
