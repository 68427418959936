import cuid from 'cuid'
import { createStore, createEvent, createEffect } from 'effector'

import { manageAuth } from 'local-storage'
import http from 'http-client'
import * as I from 'types'

/* If time one stop timer and its length is less then the value -> we are removing this time period */
const MIN_TIME_PERIOD_LENGTH_MS = 1000

export const $timeTrackerStore = createStore<I.AppState.TimeTracker>({
  fetched: false,
  trackingAimId: null /* used as connection between time periods and some separate unknown entity */,
  trackingTimePeriodId: null,
  timePeriodIdsByLinkedEntityId: new Map(),
  timePeriodById: new Map(),
})

export const $trackingAimId = $timeTrackerStore.map((s) => s.trackingAimId)

export const event = {
  timePeriod: {
    remove: createEvent<I.Model.TimePeriodId>(),
    update: createEvent<I.Model.TimePeriod>(),
  },
  tracking: {
    start: createEvent<{ trackingAimId: I.Model.AimId }>(),
    stop: createEvent(),
    toggle: createEvent(),
  },
}

export const effect = {
  timePeriod: {
    fetch: createEffect(http.fetch.timePeriods),
    merge: createEffect(http.merge.timePeriods),
    delete: createEffect(http.delete.timePeriod),
  },
}

/* Effects */

$timeTrackerStore.on(effect.timePeriod.fetch.doneData, (s, timePeriods): I.AppState.TimeTracker => {
  const timePeriodById = new Map<I.Model.TimePeriodId, I.Model.TimePeriod>(timePeriods.map((x) => [x.id, x]))
  const timePeriodIdsByAimId = new Map<I.Model.AimId, I.Model.TimePeriodId[]>()

  timePeriodById.forEach((timePeriod: I.Model.TimePeriod) => {
    const reference = timePeriodIdsByAimId.get(timePeriod.linkedEntityId)

    reference
      ? reference.push(timePeriod.id)
      : timePeriodIdsByAimId.set(timePeriod.linkedEntityId, [timePeriod.id])
  })

  const stillTrackingPeriods = timePeriodById.valuesBy((v: I.Model.TimePeriod) => !v.to)
  const trackingPeriod = stillTrackingPeriods && stillTrackingPeriods[0]

  return {
    ...s,
    fetched: true,
    trackingAimId: trackingPeriod?.linkedEntityId || null,
    trackingTimePeriodId: trackingPeriod?.id || null,
    timePeriodIdsByLinkedEntityId: timePeriodIdsByAimId,
    timePeriodById,
  }
})

/* Events */

$timeTrackerStore.on(event.tracking.start, (s, { trackingAimId }): I.AppState.TimeTracker => {
  if (s.trackingAimId === trackingAimId) {
    return s
  }

  const auth = manageAuth.get()!
  const userId = auth.id

  const newPeriod: I.Model.TimePeriod = {
    id: cuid() as I.Model.TimePeriodId,
    from: Date.now() as I.Model.Timestamp,
    linkedEntityId: trackingAimId,
    createdBy: userId,
    canRead: [userId],
    canWrite: [userId],
  }

  effect.timePeriod.merge([newPeriod])

  const relatedReference = s.timePeriodIdsByLinkedEntityId.get(newPeriod.linkedEntityId)

  s.timePeriodIdsByLinkedEntityId.set(
    newPeriod.linkedEntityId,
    relatedReference ? [...relatedReference, newPeriod.id] : [newPeriod.id]
  )

  const updatedState = {
    ...s,
    trackingAimId,
    trackingTimePeriodId: newPeriod.id,
    timePeriodById: s.timePeriodById.set(newPeriod.id, newPeriod).clone(),
    timePeriodIdsByLinkedEntityId: s.timePeriodIdsByLinkedEntityId.clone(),
  }

  if (s.trackingAimId && s.trackingTimePeriodId) {
    const currentTimePeriod = s.timePeriodById.get(s.trackingTimePeriodId)

    if (!currentTimePeriod) {
      throw new Error(`Incomplete application state no required timePeriod of id: ${s.trackingTimePeriodId}`)
    }

    const to = Date.now() as I.Model.Timestamp
    const updatedTimePeriod = { ...currentTimePeriod, to }

    updatedState.timePeriodById.set(currentTimePeriod.id, updatedTimePeriod)

    /* Remove time period immediately if spent time is too short */

    const periodLengthMS = to - currentTimePeriod.from

    if (periodLengthMS < MIN_TIME_PERIOD_LENGTH_MS) {
      updatedState.timePeriodById.delete(currentTimePeriod.id)

      const relatedReference = updatedState.timePeriodIdsByLinkedEntityId.get(
        currentTimePeriod.linkedEntityId
      )

      if (relatedReference) {
        updatedState.timePeriodIdsByLinkedEntityId.set(
          currentTimePeriod.linkedEntityId,
          relatedReference.filter((id) => id !== currentTimePeriod.id)
        )
      }

      effect.timePeriod.delete({ timePeriodId: currentTimePeriod.id })
    } else {
      effect.timePeriod.merge([updatedTimePeriod])
    }
  }

  return updatedState
})

$timeTrackerStore.on(event.tracking.stop, (s) => {
  const trackingTimePeriodId = s.trackingTimePeriodId

  if (!trackingTimePeriodId) {
    return s
  }

  const currentTimePeriod = s.timePeriodById.get(trackingTimePeriodId)

  if (!currentTimePeriod) {
    throw new Error(`Incomplete application state no required timePeriod of id: ${trackingTimePeriodId}`)
  }

  const updatedTimePeriod: I.Model.TimePeriod & { to: I.Model.Timestamp } = {
    ...currentTimePeriod,
    to: Date.now() as I.Model.Timestamp,
  }
  const periodLengthMS = updatedTimePeriod.to - updatedTimePeriod.from

  const updatedState: I.AppState.TimeTracker = {
    ...s,
    trackingAimId: null,
    trackingTimePeriodId: null,
    timePeriodById: s.timePeriodById.set(updatedTimePeriod.id, updatedTimePeriod).clone(),
  }

  if (periodLengthMS < MIN_TIME_PERIOD_LENGTH_MS) {
    updatedState.timePeriodById.delete(updatedTimePeriod.id)
    effect.timePeriod.delete({ timePeriodId: updatedTimePeriod.id })
  } else {
    effect.timePeriod.merge([updatedTimePeriod])
  }

  return updatedState
})

$timeTrackerStore.on(event.timePeriod.remove, (s, timePeriodId): I.AppState.TimeTracker => {
  const timePeriod = s.timePeriodById.get(timePeriodId)

  if (!timePeriod) return s

  const timePeriodIdsOfLinkedEntityId = s.timePeriodIdsByLinkedEntityId.get(timePeriod.linkedEntityId)

  if (!timePeriodIdsOfLinkedEntityId) return s

  effect.timePeriod.delete({ timePeriodId })

  const reference = s.timePeriodIdsByLinkedEntityId
    .get(timePeriod.linkedEntityId)!
    .filter((id) => id !== timePeriodId)

  return {
    ...s,
    timePeriodById: s.timePeriodById.omit([timePeriodId]).clone(),
    timePeriodIdsByLinkedEntityId: s.timePeriodIdsByLinkedEntityId.set(timePeriod.linkedEntityId, reference),
    trackingTimePeriodId: s.trackingTimePeriodId === timePeriodId ? null : s.trackingTimePeriodId,
    trackingAimId: s.trackingAimId === timePeriod.linkedEntityId ? null : s.trackingAimId,
  }
})

$timeTrackerStore.on(event.timePeriod.update, (s, timePeriod): I.AppState.TimeTracker => {
  effect.timePeriod.merge([timePeriod])

  s.timePeriodById.set(timePeriod.id, timePeriod)

  const timePeriodById = s.timePeriodById.clone()

  return {
    ...s,
    timePeriodById,
  }
})
