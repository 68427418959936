import cuid from 'cuid'
import { Direction, BezierSetting, Line } from './Bezier'

/* limiting a position to an area */
export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max)
export const makeHash = cuid

export const endXY = (el: Element, container: Element, direction: Direction, indent?: number) => {
  const rect = el.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()

  const offsetX = indent && indent >= 0 ? indent : rect.width / 2
  const offsetY = indent && indent >= 0 ? indent : rect.height / 2

  const xy = {
    x: rect.x - containerRect.x,
    y: rect.y - containerRect.y,
  }

  switch (direction) {
    case 'top':
      return {
        x: xy.x + offsetX,
        y: xy.y,
      }
    case 'bottom':
      return {
        x: xy.x + offsetX,
        y: xy.y + rect.height,
      }
    case 'left':
      return {
        x: xy.x,
        y: xy.y + offsetY,
      }
    case 'right':
      return {
        x: xy.x + rect.width,
        y: xy.y + offsetY,
      }

    default:
      throw new Error('unexpected type')
  }
}

export const getLine = (p: {
  setting: BezierSetting
  container: Element
  hash: string
  forbidToShowFromLeftToRightLines?: boolean
}): Line | undefined => {
  const a = document.querySelector(`.react-bezier-lines-${p.hash} [id='${p.setting.from}']`)
  const b = document.querySelector(`.react-bezier-lines-${p.hash} [id='${p.setting.to}']`)

  const result: Line = {
    id: `${p.setting.from};${p.setting.to}`,
    cord0: { x: 0, y: 0 },
    cord1: { x: 0, y: 0 },
    cord2: { x: 0, y: 0 },
    cord3: { x: 0, y: 0 },
    style: 'bezier-line__particle',
  }

  if (!a || !b) {
    return result
  }

  result.cord0 = endXY(a, p.container, p.setting.positions.start.side, p.setting.positions.start.indent)
  result.cord3 = endXY(b, p.container, p.setting.positions.end.side, p.setting.positions.end.indent)

  switch (p.setting.positions.start.side) {
    case 'top':
    case 'bottom': {
      result.cord1.x = result.cord0.x
      result.cord2.x = result.cord3.x
      break
    }
    case 'left':
    case 'right': {
      result.cord1.y = result.cord0.y
      result.cord2.y = result.cord3.y
      break
    }

    default:
      break
  }

  const halfX = Math.abs(result.cord3.x - result.cord0.x) / 2
  const halfY = Math.abs(result.cord3.y - result.cord0.y) / 2
  const clampX = clamp(halfX, 30, 100)
  const clampY = clamp(halfY, 30, 100)

  switch (p.setting.positions.end.side) {
    case 'top': {
      switch (p.setting.positions.start.side) {
        case 'top':
          result.cord1.y = result.cord0.y - clampY
          result.cord2.y = result.cord3.y - clampY
          break
        case 'bottom':
          result.cord1.y = result.cord0.y + clampY
          result.cord2.y = result.cord3.y - clampY
          break
        case 'left':
          result.cord1.x = result.cord0.x - clampY
          result.cord2.x = result.cord3.x
          result.cord2.y = result.cord3.y - clampY
          break
        case 'right':
          result.cord1.x = result.cord0.x + clampX
          result.cord2.x = result.cord3.x
          result.cord2.y = result.cord3.y - clampY
          break
        default:
          break
      }
      break
    }

    case 'bottom': {
      switch (p.setting.positions.start.side) {
        case 'bottom':
          result.cord1.y = result.cord0.y + clampY
          result.cord2.y = result.cord3.y + clampY
          break
        case 'top':
          result.cord1.y = result.cord0.y - clampY
          result.cord2.y = result.cord3.y + clampY
          break
        case 'left':
          result.cord1.x = result.cord0.x - clampX
          result.cord2.x = result.cord3.x
          result.cord2.y = result.cord3.y + clampY
          break
        case 'right':
          result.cord1.x = result.cord0.x + clampX
          result.cord2.x = result.cord3.x
          result.cord2.y = result.cord3.y + clampY
          break
        default:
          break
      }
      break
    }

    case 'left': {
      switch (p.setting.positions.start.side) {
        case 'left':
          result.cord1.x = result.cord0.x - clampX
          result.cord2.x = result.cord3.x - clampX
          break
        case 'right':
          result.cord1.x = result.cord0.x + clampX
          result.cord2.x = result.cord3.x - clampX
          break
        case 'top':
          result.cord1.y = result.cord0.y - clampY
          result.cord2.x = result.cord3.x - clampX
          result.cord2.y = result.cord3.y
          break
        case 'bottom':
          result.cord1.y = result.cord0.y + clampY
          result.cord2.y = result.cord3.y
          result.cord2.x = result.cord3.x - clampX
          break
        default:
          break
      }
      break
    }

    case 'right': {
      switch (p.setting.positions.start.side) {
        case 'right':
          result.cord2.x = result.cord3.x + clampX
          result.cord1.x = result.cord0.x + clampX
          break
        case 'left':
          result.cord2.x = result.cord3.x + clampX
          result.cord1.x = result.cord0.x - clampX
          break
        case 'top':
          result.cord1.y = result.cord0.y - clampY
          result.cord2.x = result.cord3.x + clampX
          result.cord2.y = result.cord3.y
          break
        case 'bottom':
          result.cord1.y = result.cord0.y + clampY
          result.cord2.y = result.cord3.y
          result.cord2.x = result.cord3.x + clampX
          break
        default:
          break
      }
      break
    }
    default:
      break
  }

  if (p.forbidToShowFromLeftToRightLines && result.cord0.x >= result.cord3.x) return

  return result
}
