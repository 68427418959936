import React, { useCallback } from 'react'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import './SignUpPage.scss'

import { manageAuth } from 'local-storage'
import http from 'http-client'

import UnauthLayout from 'components/UnauthLayout'
import SignUpForm, { SignUpFormConfig } from 'components/SignUpForm'
import { FIELD_NAMES } from 'components/SignUpForm/SignUpForm.cfg'

import * as I from 'types'

type Props = RouteComponentProps

const REDIRECT_TO = '/'
const REGISTRATION_IS_DISABLED = true

export default function SignUpPage(p: Props) {
  const {
    history: { replace },
  } = p

  const isAuth = !!manageAuth.get()
  const handleSubmit = useCallback(
    async (p: { username: string; password: string }) => {
      const signUpResponse = await http.auth.signUp({ username: p.username, password: p.password })

      if (signUpResponse.status === 'exception') {
        switch (signUpResponse.data) {
          case 'Provided username is already taken':
            throw {
              [FIELD_NAMES.username]: signUpResponse.data,
            }
          default:
            I.exhaustiveCheck(signUpResponse)
        }
      }

      manageAuth.set(signUpResponse.data)
      replace(REDIRECT_TO)
    },
    [replace]
  )

  if (isAuth) {
    return <Redirect to={REDIRECT_TO} />
  }

  if (REGISTRATION_IS_DISABLED)
    return (
      <UnauthLayout className="SignUpPagePlaceholder">
        <div>
          <div className="SignUpPagePlaceholder__text">It is an alpha version of the application.</div>
          <div className="SignUpPagePlaceholder__text">Registration for friends only.</div>
        </div>
      </UnauthLayout>
    )

  return (
    <UnauthLayout className="SignUpPage">
      <div className="SignUpPage__form">
        <SignUpForm schema={SignUpFormConfig.FORM_SCHEMA} handleSubmit={handleSubmit} />
      </div>
    </UnauthLayout>
  )
}
