import * as I from 'types'

export const getElId = {
  aimBranch: (aimId: I.Model.AimId) => `BRANCH_${aimId}`,
  aimItem: (aimId: I.Model.AimId) => aimId,
  treeBoardScrollEl: 'TREE_SCROLL_EL_ID',
}

export const getEl = {
  aimBranch: (aimId: I.Model.AimId) => document.getElementById(getElId.aimBranch(aimId)),
  aimItem: (aimId: I.Model.AimId) => document.getElementById(getElId.aimItem(aimId)),
  treeBoardScrollEl: () => document.getElementById(getElId.treeBoardScrollEl),
}
