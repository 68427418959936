import qs from 'qs'
import { AuthToken } from 'types'

// supported by "qs" URL encoder
type QueryParameters = Record<string, boolean | string | number | string[] | number[]>

const request = <T = any>(url: string, options?: RequestInit, on401Status?: () => void): Promise<T> =>
  fetch(url, { headers: { 'Content-Type': 'application/json', ...options?.headers }, ...options }).then(
    (res) => {
      const data = res.headers.get('Content-Type') === 'application/json' ? res.json() : null

      if (!res.ok) {
        if (res.status === 401) {
          if (on401Status) {
            on401Status()
            return
          }

          throw Error('UNAUTHORIZED')
        }

        if (!data) {
          throw data ? data : Error(res.statusText)
        }

        return data.then((error) => {
          throw error
        })
      }

      if (data) {
        return data
      }

      return res
    }
  )

export const requestAPIFactory = (
  baseURL: string,
  options?: {
    getAuthHeader?: () => AuthToken | null

    // If provided request will not throw error on 401 status but call `on401Status`
    on401Status?: () => void
  }
) => {
  const getURL = (route: string, params?: QueryParameters) =>
    `${baseURL}${route}${params ? '?' + qs.stringify(params) : ''}`

  const bodyEncode = (data: any): RequestInit['body'] => {
    if (
      data instanceof FormData ||
      data instanceof Blob ||
      data instanceof ArrayBuffer ||
      data instanceof ReadableStream ||
      data instanceof URLSearchParams ||
      ['string', 'number'].includes(typeof data)
    ) {
      return data
    }

    if (typeof data === 'object') {
      return JSON.stringify(data)
    }
  }

  const authorizationHeader = () => {
    return options?.getAuthHeader?.call(undefined) || ''
  }

  const getReq = <T>(route: string, params?: QueryParameters, reqOptions?: RequestInit) => {
    return request<T>(
      getURL(route, params),
      {
        ...reqOptions,
        method: 'GET',
        headers: { Authorization: authorizationHeader() },
      },
      options?.on401Status
    )
  }

  // Request with body data payload
  const req =
    <T>(method: string) =>
    (route: string, data?: any, params?: QueryParameters, reqOptions?: RequestInit) => {
      return request<T>(
        getURL(route, params),
        {
          ...reqOptions,
          method,
          body: bodyEncode(data),
          headers: { Authorization: authorizationHeader() },
        },
        options?.on401Status
      )
    }

  type R = Parameters<ReturnType<typeof req>>

  return {
    get: <T>(...args: Parameters<typeof getReq>) => getReq<T>(...args),
    post: <T>(...args: R) => req<T>('POST')(...args),
    put: <T>(...args: R) => req<T>('PUT')(...args),
    patch: <T>(...args: R) => req<T>('PATCH')(...args),
    merge: <T>(...args: R) => req<T>('MERGE')(...args),
    delete: <T>(...args: R) => req<T>('DELETE')(...args),
  }
}
