import React, { useEffect } from 'react'
import { useStore } from 'effector-react'
import { useHistory } from 'react-router-dom'

import * as feature from 'features'

const MemoizedDataProvided = React.memo(function MemoizedDataProvider(p: { element: JSX.Element }) {
  return p.element
})

export const Loading = () => <div>Loading...</div>

const mountChangeRoute = () => {
  const history = useHistory()

  if (!window.changeRoute) {
    window.changeRoute = history.push
  }
}

export default function DataProvider(p: { element: JSX.Element }) {
  mountChangeRoute()

  const timeTrackerState = useStore(feature.timeTracker.$timeTrackerStore)
  const treeBoardState = useStore(feature.treeBoard.$treeBoardStore)

  useEffect(() => {
    if (!treeBoardState.fetched) feature.treeBoard.effect.fetchBoard(undefined)
    if (!timeTrackerState.fetched) feature.timeTracker.effect.timePeriod.fetch(undefined)
  }, [])

  if (!treeBoardState.fetched || !timeTrackerState.fetched) {
    return <Loading />
  }

  return <MemoizedDataProvided element={p.element} />
}
