import React from 'react'
import Modal, { useModal } from './'

import * as I from 'types'

const DESCRIPTION = 'Modal component + useModal hook'

function ModalShowcase() {
  const modal = useModal()
  const subModal = useModal()

  return (
    <div>
      <button onClick={modal.show}>OPEN MODAL</button>

      {modal.isOpen && (
        <Modal onOverlayClick={modal.hide}>
          <div>
            <button onClick={subModal.show}>OPEN SUB MODAL</button>

            {subModal.isOpen && (
              <Modal onOverlayClick={subModal.hide}>
                <button onClick={subModal.hide}>CLOSE SUB MODAL</button>
              </Modal>
            )}
          </div>
        </Modal>
      )}
    </div>
  )
}

export default [ModalShowcase, 'modal', ['Modal', DESCRIPTION]] as I.ComponentShowcase
